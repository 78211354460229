import { Search } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import GooglePlace from "./GooglePlace";

function BusinessUnitGooglePlace({
  search: searchInput,
  googlePlace,
  places = [],
  businessName,
  onChange,
  variant,
}) {
  const [loadingPlaces, setLoadingPlaces] = useState(true);
  const search = searchInput || businessName;

  useEffect(() => {
    findPlaces();
  }, []);

  async function findPlaces() {
    setLoadingPlaces(true);
    const { AutocompleteService } =
      await window.google.maps.importLibrary("places");
    const autocompleteService = new AutocompleteService();
    autocompleteService.getPlacePredictions(
      {
        input: search,
        componentRestrictions: { country: "mx" },
        language: "es-419",
      },
      (places) => {
        setLoadingPlaces(false);
        onChange({ places: places || [] });
      },
    );
  }

  return (
    <>
      <Box
        sx={{
          maxWidth: variant === "dialog" ? "auto" : 500,
          display: "flex",
          alignItems: "center",
          pt: 2,
        }}
      >
        <Box
          component="img"
          src="/google-maps.svg"
          alt=""
          sx={{
            height: 50,
            mr: 2,
          }}
        />
        <Typography variant="h5">
          {variant === "dialog"
            ? `Ayúdanos a encontrar a ${businessName} en Google Maps`
            : "Ayúdanos a encontrarlo en Google Maps"}
        </Typography>
      </Box>
      <Box sx={{ maxWidth: 500 }}>
        <TextField
          variant="outlined"
          fullWidth
          autoFocus
          onFocus={(e) => e.target.select()}
          value={search}
          onChange={(e) => onChange({ search: e.target.value })}
          onKeyDown={(e) => {
            if (e.key === "Enter" && search && search.length >= 3) {
              onChange({ googlePlace: null });
              findPlaces();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {loadingPlaces ? (
                  <CircularProgress size={32} />
                ) : (
                  <IconButton
                    disabled={!search || search.length < 3}
                    onClick={() => {
                      onChange({
                        googlePlace: null,
                      });
                      findPlaces();
                    }}
                  >
                    <Search />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {!loadingPlaces && places.length === 0 && (
        <Box>
          <Typography sx={{ mb: 1 }} color="error">
            No encontramos resultados en Google Maps, prueba buscar con otros
            términos (nombres comerciales, direcciones, etc).
          </Typography>
        </Box>
      )}

      {!loadingPlaces && places.length > 0 && (
        <>
          <Box>
            <Typography sx={{ mb: 1 }}>
              Encontramos los siguientes resultados, si uno de ellos es tu
              Concesionario, selecciónalo:
            </Typography>
            <Grid container spacing={1}>
              {places.map((place) => (
                <Grid
                  key={place.place_id}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={variant === "dialog" ? 4 : 3}
                  sx={{ display: "flex" }}
                >
                  <GooglePlace
                    autocompletePlace={place}
                    select={(newGooglePlace) => {
                      if (
                        googlePlace &&
                        googlePlace.place_id === newGooglePlace.place_id
                      ) {
                        onChange({
                          schedule: [],
                          googlePlace: null,
                          storefront: null,
                        });
                        return;
                      }

                      onChange({
                        schedule: [],
                        googlePlace: {
                          ...newGooglePlace,
                          photos: (newGooglePlace.photos || []).map(
                            (photo) => ({
                              ...photo,
                              url: photo.getUrl(),
                            }),
                          ),
                        },
                        storefront:
                          newGooglePlace.photos && newGooglePlace.photos.length
                            ? newGooglePlace.photos[0].getUrl()
                            : null,
                      });
                    }}
                    selected={
                      googlePlace && place.place_id === googlePlace.place_id
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Typography sx={{ mb: 1 }}>
            ¿No encuentras tu Concesionario? Prueba buscar con otros términos
            (nombres comerciales, direcciones, etc).
          </Typography>
        </>
      )}
    </>
  );
}

export default BusinessUnitGooglePlace;
