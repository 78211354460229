import { Business, Store } from "@mui/icons-material";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";

function GettingStartedBusinessName({ initialState, nextStep, prevStep }) {
  const [businessName, setBusinessName] = useState(
    initialState.businessName || ""
  );

  return (
    <GettingStartedLayout
      image="/sedan-blanco.png"
      nextStep={() => nextStep({ businessName })}
      prevStep={() => prevStep({ businessName })}
      disabled={!businessName || !businessName.trim()}
    >
      <Box>
        <Typography variant="h5">
          {`¿Cómo se llama tu ${
            initialState.businessType === "business-unit"
              ? "Concesionario"
              : "grupo de Concesionarios"
          }?`}
        </Typography>
      </Box>
      <Box>
        <TextField
          variant="outlined"
          fullWidth
          autoFocus
          value={businessName}
          onChange={(e) => setBusinessName(e.target.value)}
          onKeyDown={(e) =>
            e.key === "Enter" && businessName && businessName.trim()
              ? nextStep({ businessName })
              : null
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {initialState.businessType === "business-unit" ? (
                  <Store />
                ) : (
                  <Business />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedBusinessName;
