import GettingStartedLayout from "./GettingStartedLayout";
import moment from "moment";
import { useEffect, useState } from "react";
import ScheduleVerification from "./ScheduleVerification";
import useLocalStorage from "../hooks/useLocalStorage";
import { jwtDecode } from "jwt-decode";
import { Box, Typography } from "@mui/material";

function GettingStartedVerification({ initialState, nextStep, prevStep }) {
  const [date, setDate] = useState(
    moment().day() >= 5 // friday or saturday?
      ? moment().add(1, "week").day(1).format("YYYY-MM-DD") // monday
      : moment().add(1, "day").format("YYYY-MM-DD"), // tomorrow
  );
  const [timeSlot, setTimeSlot] = useState(null);
  const [authToken] = useLocalStorage("authToken", null);
  const [isMaster, setIsMaster] = useState(false);

  useEffect(() => {
    const decodedToken = jwtDecode(authToken);
    setIsMaster(decodedToken.dat.master || false);
  }, [authToken]);

  if (isMaster) {
    return (
      <GettingStartedLayout
        noImages
        nextStep={async () => {
          const result = await nextStep({ verified: true }, 0);
          window.location = `https://app.intelimotor.com/sign-in?authToken=${
            result.data.changeVentauGettingStartedState
          }&redirect=${encodeURIComponent("/ticket-analytics/ventau")}`;
        }}
        nextStepText="Verificar negocio"
        prevStep={() => prevStep({})}
      >
        <Box>
          <Typography variant="h5">¡Está todo listo!</Typography>
          <Typography>
            Al verificar este negocio, se crearán las unidades de negocio
            correspondientes y el usuario podrá comenzar a usar su cuenta.
          </Typography>
        </Box>
      </GettingStartedLayout>
    );
  }

  return (
    <>
      <GettingStartedLayout
        image="/support.png"
        noImageTransformations
        flexStart
        nextStep={() =>
          nextStep({ date, timeSlot }, 1, initialState.rescheduling)
        }
        nextStepText={initialState.rescheduling ? "Reagendar" : "Agendar"}
        prevStep={
          !initialState.rescheduling ? () => prevStep({ date, timeSlot }) : null
        }
        disabled={!date || !timeSlot}
      >
        <ScheduleVerification
          date={date}
          setDate={setDate}
          timeSlot={timeSlot}
          setTimeSlot={setTimeSlot}
        />
      </GettingStartedLayout>
    </>
  );
}

export default GettingStartedVerification;
