import { AddAPhotoOutlined, Delete } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  CircularProgress,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { gql, useMutation } from "@apollo/client";
import mime from "mime";

import PictureButton from "./PictureButton";
import { useEffect, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { useSnackbar } from "notistack";

const UPLOAD_FILE = gql`
  mutation UploadFile(
    $file: String!
    $extension: String!
    $resizeWidth: Int
    $resizeHeight: Int
    $fit: String
    $backgroundColor: String
  ) {
    uploadedFile: uploadFile(
      file: $file
      extension: $extension
      resizeWidth: $resizeWidth
      resizeHeight: $resizeHeight
      fit: $fit
      backgroundColor: $backgroundColor
    )
  }
`;

function CustomPicture({
  selectedUrl,
  onChange,
  variant = "storefront",
  defaults = [],
  reactiveUrl = false,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [authToken] = useLocalStorage("authToken", null);
  const [upload, { loading: uploading }] = useMutation(UPLOAD_FILE, {
    context: { headers: { Authorization: ["Bearer", authToken].join(" ") } },
  });
  const [preview, setPreview] = useState(null);
  const [url, setUrl] = useState(
    selectedUrl &&
      !selectedUrl.includes("maps.googleapis.com") &&
      !defaults.includes(selectedUrl)
      ? selectedUrl
      : null,
  );

  useEffect(() => {
    if (defaults.includes(url)) setUrl(null);
  }, [defaults, url]);

  useEffect(() => {
    if (reactiveUrl && (!selectedUrl || !selectedUrl.includes("blob"))) {
      setUrl(selectedUrl);
      setPreview(null);
    }
  }, [reactiveUrl, selectedUrl]);

  const src = url || preview;

  return (
    <>
      {Boolean(src) && (
        <Box
          sx={{
            position: "relative",
            borderRadius: 1,
            overflow:
              variant === "logo" || variant === "profile"
                ? "visible"
                : "hidden",
          }}
        >
          {url && (
            <IconButton
              sx={{
                position: "absolute",
                top: variant === "logo" ? "-8px" : "4px",
                right: variant === "logo" ? "-8px" : "4px",
                zIndex: 1,
                backgroundColor: "rgba(0, 0, 0, 0.75) !important",
                transition: "all 100ms",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.9) !important",
                },

                ...(variant === "profile"
                  ? {
                      top: "50%",
                      left: "24px",
                      right: "auto",
                      transform: "translateX(-50%) translateY(-50%)",
                      opacity: 0,
                      "&:hover": {
                        opacity: 1,
                      },
                    }
                  : {}),
              }}
              onClick={() => {
                setUrl(null);
                setPreview(null);
                onChange(null);
              }}
            >
              <Delete sx={{ color: "#fff" }} />
            </IconButton>
          )}
          {!url && (
            <LinearProgress
              color="secondary"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width:
                  variant === "logo" || variant === "profile"
                    ? "calc(100% - 8px)"
                    : "100%",
                height: 8,
                zIndex: 1,
              }}
            />
          )}
          <PictureButton
            src={src}
            selected={[preview, url].filter(Boolean).includes(selectedUrl)}
            onClick={() => onChange(url)}
            disabled={!url}
            variant={variant}
          />
        </Box>
      )}
      {!src && (
        <ButtonBase
          sx={{
            width: "100%",
            height: 200,
            overflow: "hidden",
            borderRadius: 1,
            border: "1px solid #ccc",
            ...(variant === "logo"
              ? {
                  width: 80,
                  height: 80,
                  mr: 1,
                  mb: 1,
                }
              : {}),
            ...(variant === "profile"
              ? {
                  width: 48,
                  height: 48,
                  mr: 2,
                  borderRadius: "100%",
                }
              : {}),
          }}
          onClick={() => {}}
          component="label"
          labelFor="custom-picture"
        >
          {!uploading ? <AddAPhotoOutlined /> : <CircularProgress size={48} />}
          <Box
            component="input"
            type="file"
            label="custom-picture"
            sx={{ display: "none" }}
            accept="image/*"
            onChange={(e) => {
              const [file] = e.target.files;
              if (file) {
                if (!file.type.includes("image")) {
                  enqueueSnackbar("Solo se admiten imágenes.", {
                    variant: "error",
                  });
                  return;
                }

                // preview
                const preview = URL.createObjectURL(file);
                setPreview(preview);
                onChange(preview);

                // upload
                const fileReader = new FileReader();
                fileReader.addEventListener("load", (e) => {
                  upload({
                    variables: {
                      file: e.target.result,
                      extension: mime.getExtension(file.type),
                      ...(variant === "logo"
                        ? {
                            resizeWidth: 120,
                            resizeHeight: 120,
                            fit: "contain",
                            backgroundColor: [
                              "image/png",
                              "image/webp",
                            ].includes(file.type)
                              ? "#ffffff00"
                              : "#ffffffff",
                          }
                        : {}),
                      ...(variant === "profile"
                        ? {
                            resizeWidth: 320,
                            resizeHeight: 320,
                            fit: "cover",
                            backgroundColor: [
                              "image/png",
                              "image/webp",
                            ].includes(file.type)
                              ? "#ffffff00"
                              : "#ffffffff",
                          }
                        : {}),
                    },
                    onCompleted({ uploadedFile }) {
                      setUrl(uploadedFile);
                      onChange(uploadedFile);
                    },
                  });
                });
                fileReader.readAsDataURL(file);
              }
            }}
          />
        </ButtonBase>
      )}
    </>
  );
}

export default CustomPicture;
