import { Box, Chip, Typography } from "@mui/material";
import { useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";

function GettingStartedInspectionTypes({ initialState, nextStep, prevStep }) {
  const [inspectionTypes, setInspectionTypes] = useState(
    initialState.inspectionTypes || []
  );

  return (
    <GettingStartedLayout
      image="/sedan-negro.png"
      nextStep={() => nextStep({ inspectionTypes })}
      prevStep={() => prevStep({ inspectionTypes })}
      disabled={inspectionTypes.length === 0}
    >
      <Box>
        <Typography variant="h5">
          ¿Dónde puedes realizar tus inspecciones?
        </Typography>
        <Typography>Puedes seleccionar varias opciones.</Typography>
      </Box>
      <Box>
        {[
          { type: "business", label: "En tu Concesionario" },
          { type: "home", label: "A domicilio" },
          { type: "remote", label: "Virtuales" },
        ].map(({ type, label }) => (
          <Chip
            key={type}
            label={label}
            sx={{ mr: 1, mb: 1 }}
            variant="outlined"
            onClick={() =>
              setInspectionTypes(
                inspectionTypes.includes(type)
                  ? inspectionTypes.filter((item) => item !== type)
                  : [...inspectionTypes, type]
              )
            }
            {...(inspectionTypes.includes(type)
              ? {
                  variant: "filled",
                  color: "primary",
                  className: "gradient",
                }
              : {})}
          />
        ))}
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedInspectionTypes;
