import {
  Box,
  FormControlLabel,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";
import toNumber from "../utils/toNumber";

function GettingStartedMaxKms({ initialState, nextStep, prevStep }) {
  const [maxKms, setMaxKms] = useState(initialState.maxKms || "");
  const [noMaxKms, setNoMaxKms] = useState(initialState.noMaxKms || false);

  return (
    <GettingStartedLayout
      image="/sedan-blanco-2.png"
      nextStep={() =>
        nextStep(
          { maxKms, noMaxKms },
          initialState.businessUnits
            .filter((businessUnit) => businessUnit.googlePlace)
            .some((businessUnit) => !businessUnit.noOwnBrands)
            ? 1
            : 2,
        )
      }
      prevStep={() => prevStep({ maxKms, noMaxKms })}
      disabled={!noMaxKms && (!maxKms || !maxKms.trim())}
    >
      <Box>
        <Typography variant="h5">
          ¿Arriba de qué kilometraje ya no te interesa comprar autos?
        </Typography>
      </Box>
      {!noMaxKms && (
        <Box>
          <TextField
            variant="outlined"
            autoFocus
            value={maxKms ? toNumber(maxKms) : ""}
            onChange={(e) => setMaxKms(e.target.value.replace(/\D/g, ""))}
            onKeyDown={(e) =>
              e.key === "Enter" && maxKms && maxKms.trim()
                ? nextStep({ maxKms })
                : null
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">km</InputAdornment>,
              componentsProps: {
                input: {
                  min: 0,
                },
              },
            }}
            placeholder="Ej. 100,000"
          />
        </Box>
      )}
      <Box>
        <FormControlLabel
          control={
            <Switch
              checked={noMaxKms}
              onChange={(e) => setNoMaxKms(e.target.checked)}
            />
          }
          label="Compramos autos de cualquier kilometraje"
        />
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedMaxKms;
