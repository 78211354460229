import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import usePrevious from "../hooks/usePrevious";
import Schedule from "./Schedule";

function BusinessUnitSchedule({ schedule, googlePlace, onChange }) {
  const prevGooglePlace = usePrevious(googlePlace);

  useEffect(() => {
    if (
      schedule.length > 0 &&
      (!prevGooglePlace || prevGooglePlace.place_id === googlePlace.place_id)
    )
      return;

    const validPeriods =
      googlePlace.opening_hours && googlePlace.opening_hours.periods
        ? googlePlace.opening_hours.periods
            .filter((period) => period.open && period.close)
            .filter(
              (period, idx, arr) =>
                arr.findIndex((item) => item.open.day === period.open.day) ===
                idx,
            )
        : [];

    const newSchedule =
      validPeriods.length > 2
        ? validPeriods.map((period) => ({
            weekdays: [period.open.day],
            fromHours: period.open.hours,
            fromMinutes: period.open.minutes,
            toHours: period.close.hours,
            toMinutes: period.open.minutes,
          }))
        : [
            ...[1, 2, 3, 4, 5].map((day) => ({
              weekdays: [day],
              fromHours: 9,
              fromMinutes: 0,
              toHours: 18,
              toMinutes: 0,
            })),
            {
              weekdays: [6],
              fromHours: 10,
              fromMinutes: 0,
              toHours: 13,
              toMinutes: 0,
            },
          ];
    onChange(newSchedule);
  }, [googlePlace.place_id]);

  return (
    <Box>
      <Typography sx={{ mb: 1 }}>
        Selecciona los horarios en que puedes recibir a los vendedores
        particulares en tu Concesionario para inspeccionar sus autos.
      </Typography>
      <Schedule schedule={schedule} onChange={onChange} />
    </Box>
  );
}

export default BusinessUnitSchedule;
