import { gql, useQuery } from "@apollo/client";
import {
  Box,
  Chip,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { Search } from "@mui/icons-material";
import predictBrands from "../utils/predictBrands";

const GET_BRANDS = gql`
  query GetBrands($type: String, $parents: [ID]) {
    brands: entities(type: $type, parents: $parents) {
      id
      name
      attributes {
        name
        value
      }
    }
  }
`;

function BusinessUnitOwnBrands({
  ownBrands = [],
  noOwnBrands = false,
  businessName,
  onChange,
}) {
  const [authToken] = useLocalStorage("authToken", null);
  const [search, setSearch] = useState("");

  const { data, loading } = useQuery(GET_BRANDS, {
    context: { headers: { Authorization: ["Bearer", authToken].join(" ") } },
    variables: {
      type: "brand",
      parents: ["5cdc482fe9b82b87f832bcf7"],
    },
    onCompleted({ brands }) {
      if (ownBrands.length === 0) {
        const predictedBrands = predictBrands([businessName], brands);
        onChange({ ownBrands: predictedBrands });
      }
    },
  });

  return (
    <Stack spacing={3}>
      <Box>
        <Typography>
          ¿Qué marcas representa tu Concesionario? Puedes seleccionar varias
          opciones.
        </Typography>
      </Box>
      <Box>
        <TextField
          variant="outlined"
          fullWidth
          autoFocus
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          placeholder="Buscar marcas"
          disabled={noOwnBrands}
          sx={{ backgroundColor: "white" }}
        />
      </Box>
      <Box>
        {loading && <CircularProgress size={64} />}
        {data &&
          data.brands &&
          data.brands
            .filter(
              (brand) =>
                !search ||
                !search.trim() ||
                brand.name.toLowerCase().includes(search.trim().toLowerCase()),
            )
            .map((brand) => (
              <Chip
                key={brand.id}
                label={brand.name}
                sx={{
                  mr: 1,
                  mb: 1,
                  backgroundColor: "white",
                  "&:hover": { backgroundColor: "#eee !important" },
                }}
                variant="outlined"
                onClick={() =>
                  onChange({
                    ownBrands: ownBrands.some(
                      (ownBrand) => ownBrand.id === brand.id,
                    )
                      ? ownBrands.filter((ownBrand) => ownBrand.id !== brand.id)
                      : [...ownBrands, brand],
                  })
                }
                {...(ownBrands.some((ownBrand) => ownBrand.id === brand.id) &&
                !noOwnBrands
                  ? {
                      variant: "filled",
                      color: "primary",
                      className: "gradient",
                    }
                  : {})}
                disabled={noOwnBrands}
              />
            ))}
      </Box>
      <Box>
        {/* lot */}
        <FormControlLabel
          control={
            <Switch
              checked={noOwnBrands}
              onChange={(e) => {
                onChange({ noOwnBrands: e.target.checked });
                setSearch("");
              }}
              disabled={loading}
            />
          }
          label={
            <>
              <Typography>
                No estoy afiliado a ninguna marca fabricante de autos
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Selecciona esta opción si tienes tu propia marca para
                comercializar seminuevos.
              </Typography>
            </>
          }
          sx={{ alignItems: "flex-start" }}
        />
      </Box>
    </Stack>
  );
}

export default BusinessUnitOwnBrands;
