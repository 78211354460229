import { Box, Button, ButtonGroup, TextField, Typography } from "@mui/material";
import { useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";

function GettingStartedTimeToPayment({ initialState, nextStep, prevStep }) {
  const [timeToPaymentUnit, setTimeToPaymentUnit] = useState(
    initialState.timeToPaymentUnit || "days",
  );

  const [timeToPayment, setTimeToPayment] = useState(
    initialState.timeToPayment || null,
  );

  return (
    <GettingStartedLayout
      image="/sedan-rojo.png"
      nextStep={() => nextStep({ timeToPayment, timeToPaymentUnit })}
      prevStep={() => prevStep({ timeToPayment, timeToPaymentUnit })}
      disabled={!timeToPayment}
    >
      <Box>
        <Typography variant="h5">
          ¿En cuánto tiempo realizan los pagos para la compra de unidades?
        </Typography>
        <Typography>
          Indica si quieres expresarlo en días u horas y la cantidad.
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItem: "center" }}>
        <TextField
          variant="outlined"
          autoFocus
          type="number"
          value={timeToPayment}
          onChange={(e) => setTimeToPayment(e.target.value.replace(/\D/g, ""))}
          onKeyDown={(e) =>
            e.key === "Enter" && timeToPayment
              ? nextStep({ timeToPayment, timeToPaymentUnit })
              : null
          }
          sx={{ mr: 1, width: 100 }}
          InputProps={{
            componentsProps: {
              input: {
                min: 1,
              },
            },
          }}
          placeholder="Ej. 1"
        />
        <ButtonGroup>
          <Button
            variant={timeToPaymentUnit === "days" ? "contained" : "outlined"}
            onClick={() => setTimeToPaymentUnit("days")}
          >
            Días
          </Button>
          <Button
            variant={timeToPaymentUnit === "hours" ? "contained" : "outlined"}
            onClick={() => setTimeToPaymentUnit("hours")}
          >
            Horas
          </Button>
        </ButtonGroup>
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedTimeToPayment;
