import { Box, Chip, Typography } from "@mui/material";
import { useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";

function GettingStartedBusinessType({ initialState, nextStep, prevStep }) {
  const [businessType, setBusinessType] = useState(
    initialState.businessType || null
  );

  return (
    <GettingStartedLayout
      image="/sedan-blanco.png"
      disabled={!businessType}
      nextStep={() => nextStep({ businessType })}
      prevStep={() => prevStep({ businessType })}
    >
      <Box>
        <Typography variant="h5">¿Qué tipo de negocio representas?</Typography>
        <Typography>Selecciona una opción.</Typography>
      </Box>
      <Box>
        {[
          { type: "business-unit", label: "Concesionario individual" },
          { type: "group", label: "Grupo de Concesionarios" },
        ].map(({ type, label }) => (
          <Chip
            key={type}
            label={label}
            sx={{ mr: 1, mb: 1 }}
            variant="outlined"
            onClick={() => setBusinessType(type)}
            {...(businessType === type
              ? {
                  variant: "filled",
                  color: "primary",
                  className: "gradient",
                }
              : {})}
          />
        ))}
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedBusinessType;
