import {
  Box,
  CircularProgress,
  Hidden,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { v4 as uuid } from "uuid";

import BusinessUnit from "./BusinessUnit";
import GettingStartedLayout from "./GettingStartedLayout";
import { useSearchParams } from "react-router-dom";

function GettingStartedLocationsSingle({
  initialState,
  nextStep,
  prevStep,
  saveState,
  lastStep,
  ownProfile,
}) {
  const [searchParams] = useSearchParams();
  const [businessUnit, setBusinessUnit] = useState(
    initialState.businessUnits && initialState.businessUnits.length
      ? initialState.businessUnits[0]
      : { businessName: initialState.businessName },
  );
  const [saving, setSaving] = useState(false);

  const variant = searchParams.get("variant") || "setup";

  return (
    <GettingStartedLayout noImages lg={12} xl={12}>
      <Box sx={{ maxWidth: 500 }}>
        <Typography variant="h5">{`Configura el perfil de ${initialState.businessName}`}</Typography>
        <Typography>
          Estos serán los datos que los vendedores particulares verán al
          seleccionar tu Concesionario para vender su auto.
        </Typography>
      </Box>
      <Box sx={{ position: "relative" }}>
        <BusinessUnit
          businessName={initialState.businessName}
          businessUnit={businessUnit}
          onChange={(newBusinessUnit) => setBusinessUnit(newBusinessUnit)}
          prevStep={() =>
            prevStep({
              businessUnits: [
                {
                  id: uuid(),
                  businessName: initialState.businessName,
                  ...businessUnit,
                },
              ],
            })
          }
          nextStep={() =>
            nextStep({
              businessUnits: [
                {
                  id: uuid(),
                  businessName: initialState.businessName,
                  ...businessUnit,
                },
              ],
            })
          }
          firstStep={
            lastStep === "InspectionTypes" && variant === "setup" ? 7 : 0
          }
          variant={variant}
          ownProfile={ownProfile}
        />
        <Hidden lgDown>
          <Box
            component="img"
            src="/v.svg"
            alt=""
            sx={{
              maxWidth: "50%",
              transform: "translateX(100px)",
              position: "absolute",
              right: 0,
              top: 0,
              zIndex: -1,
              opacity: 0.05,
            }}
          />
        </Hidden>
      </Box>
      <Box sx={{ pt: 4 }}>
        <Typography variant="body2" color="textSecondary">
          ¿Tienes más de un concesionario?&nbsp;
          {saving ? (
            <CircularProgress size={14} />
          ) : (
            <Tooltip title="Ir a la versión para Grupos de Concesionarios">
              <Link
                onClick={async () => {
                  setSaving(true);
                  await saveState({ businessType: "group" });
                  setSaving(false);
                }}
                sx={{ cursor: "pointer" }}
              >
                Haz clic aquí
              </Link>
            </Tooltip>
          )}
        </Typography>
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedLocationsSingle;
