import { useEffect } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

function SignOut() {
  const [, setAuthToken] = useLocalStorage("authToken", null);
  const navigate = useNavigate();

  useEffect(() => {
    setAuthToken(null);
    setTimeout(() => navigate("/"), 1000);
  }, [setAuthToken, navigate]);

  return <Typography sx={{ p: 2 }}>Saliendo...</Typography>;
}

export default SignOut;
