import { Box, Chip, Typography } from "@mui/material";
import { useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";

function GettingStartedNotFullyPaid({ initialState, nextStep, prevStep }) {
  const [notfullypaid, setNotFullyPaid] = useState(
    typeof initialState.notfullypaid !== "undefined" &&
      initialState.notfullypaid !== null
      ? initialState.notfullypaid
      : null,
  );

  return (
    <GettingStartedLayout
      image="/sedan-negro.png"
      nextStep={() => nextStep({ notfullypaid })}
      prevStep={() => prevStep({ notfullypaid })}
      disabled={notfullypaid === null}
    >
      <Box>
        <Typography variant="h5">
          ¿Compras autos con financiamiento activo?
        </Typography>
        <Typography>Selecciona una opción.</Typography>
      </Box>
      <Box>
        {[
          { type: true, label: "Si" },
          { type: false, label: "No" },
        ].map(({ type, label }) => (
          <Chip
            key={label}
            label={label}
            sx={{ mr: 1, mb: 1, minWidth: 80 }}
            variant="outlined"
            onClick={() => setNotFullyPaid(type)}
            {...(notfullypaid === type
              ? {
                  variant: "filled",
                  color: "primary",
                  className: "gradient",
                }
              : {})}
          />
        ))}
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedNotFullyPaid;
