import {
  Box,
  Chip,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";

function GettingStartedConsignmentsFees({ initialState, nextStep, prevStep }) {
  const [consignmentsFeeType, setConsignmentsFeeType] = useState(
    typeof initialState.consignmentsFeeType !== "undefined" &&
      initialState.consignmentsFeeType !== null
      ? initialState.consignmentsFeeType
      : null,
  );

  const [consignmentsFee, setConsignmentsFee] = useState(
    typeof initialState.consignmentsFee !== "undefined" &&
      initialState.consignmentsFee !== null
      ? initialState.consignmentsFee
      : null,
  );

  return (
    <GettingStartedLayout
      image="/sedan-negro.png"
      nextStep={() => nextStep({ consignmentsFeeType, consignmentsFee })}
      prevStep={() => prevStep({ consignmentsFeeType, consignmentsFee })}
      disabled={
        consignmentsFeeType === null ||
        !consignmentsFee ||
        consignmentsFee <= 0 ||
        (consignmentsFeeType === "percentage" && consignmentsFee > 99)
      }
    >
      <Box>
        <Typography variant="h5">
          ¿Cuál es tu comisión por consignación?
        </Typography>
        <Typography>Indica el tipo de comisión que manejas.</Typography>
      </Box>
      <Box>
        {[
          { type: "percentage", label: "Porcentaje" },
          { type: "amount", label: "Monto fijo" },
        ].map(({ type, label }) => (
          <Chip
            key={label}
            label={label}
            sx={{ mr: 1, mb: 1, minWidth: 80 }}
            variant="outlined"
            onClick={() => {
              setConsignmentsFee('');
              setConsignmentsFeeType(type);
            }}
            {...(consignmentsFeeType === type
              ? {
                  variant: "filled",
                  color: "primary",
                  className: "gradient",
                }
              : {})}
          />
        ))}
      </Box>

      {!!consignmentsFeeType && (
        <Box>
          <Typography sx={{ mb: 1 }}>
            {consignmentsFeeType === "percentage"
              ? "Porcentaje fijo de comisión"
              : "Monto fijo de comisión"}
          </Typography>
          <TextField
            variant="outlined"
            autoFocus
            value={consignmentsFee}
            type="number"
            onChange={(e) => setConsignmentsFee(e.target.value)}
            InputProps={
              consignmentsFeeType === "percentage"
                ? {
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                    componentsProps: {
                      input: {
                        min: 1,
                        max: 99,
                      },
                    },
                  }
                : {
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    componentsProps: {
                      input: {
                        min: 1,
                      },
                    },
                  }
            }
            placeholder={
              consignmentsFeeType === "percentage" ? "Ej. 5" : "Ej. 5,000"
            }
          />
        </Box>
      )}
    </GettingStartedLayout>
  );
}

export default GettingStartedConsignmentsFees;
