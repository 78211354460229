import { Box, Chip, Typography } from "@mui/material";
import { useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";

function GettingStartedPaymentTypes({ initialState, nextStep, prevStep }) {
  const [paymentTypes, setPaymentTypes] = useState(
    initialState.paymentTypes || []
  );

  return (
    <GettingStartedLayout
      image="/sedan-rojo.png"
      nextStep={() => nextStep({ paymentTypes })}
      prevStep={() => prevStep({ paymentTypes })}
      disabled={paymentTypes.length === 0}
    >
      <Box>
        <Typography variant="h5">¿Qué tipos de pagos manejas?</Typography>
        <Typography>Puedes seleccionar varias opciones.</Typography>
      </Box>
      <Box>
        {[
          { type: "transfer", label: "Transferencia" },
          { type: "cash", label: "Efectivo" },
          { type: "check", label: "Cheque" },
        ].map(({ type, label }) => (
          <Chip
            key={type}
            label={label}
            sx={{ mr: 1, mb: 1 }}
            variant="outlined"
            onClick={() =>
              setPaymentTypes(
                paymentTypes.includes(type)
                  ? paymentTypes.filter((item) => item !== type)
                  : [...paymentTypes, type]
              )
            }
            {...(paymentTypes.includes(type)
              ? {
                  variant: "filled",
                  color: "primary",
                  className: "gradient",
                }
              : {})}
          />
        ))}
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedPaymentTypes;
