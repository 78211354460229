import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";

function GettingStartedMinYear({ initialState, nextStep, prevStep }) {
  const [minYear, setMinYear] = useState(initialState.minYear || "");

  return (
    <GettingStartedLayout
      image="/sedan-blanco-2.png"
      nextStep={() => nextStep({ minYear })}
      prevStep={() => prevStep({ minYear })}
      disabled={!minYear || !minYear.trim()}
    >
      <Box>
        <Typography variant="h5">
          ¿A partir de qué año te interesa comprar autos?
        </Typography>
        <Typography>Selecciona una opción.</Typography>
      </Box>
      <Box>
        <FormControl sx={{ minWidth: 140 }}>
          <InputLabel id="demo-simple-select-label">Año</InputLabel>
          <Select
            value={minYear}
            onChange={(e) => setMinYear(e.target.value)}
            labelId="demo-simple-select-label"
            label="Año"
          >
            {[...new Array(12)]
              .map((item, idx) => String(moment().year() + 1 - idx))
              .map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedMinYear;
