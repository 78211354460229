import { Box, Chip, Typography } from "@mui/material";
import { useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";

function GettingStartedConsignments({ initialState, nextStep, prevStep }) {
  const [consignments, setConsignments] = useState(
    typeof initialState.consignments !== "undefined" &&
      initialState.consignments !== null
      ? initialState.consignments
      : null,
  );

  return (
    <GettingStartedLayout
      image="/sedan-negro.png"
      nextStep={() => nextStep({ consignments }, consignments ? 1 : 6)}
      prevStep={() => prevStep({ consignments })}
      disabled={consignments === null}
    >
      <Box>
        <Typography variant="h5">¿Ofreces servicio de consignación?</Typography>
        <Typography>Selecciona una opción.</Typography>
      </Box>
      <Box>
        {[
          { type: true, label: "Si" },
          { type: false, label: "No" },
        ].map(({ type, label }) => (
          <Chip
            key={label}
            label={label}
            sx={{ mr: 1, mb: 1, minWidth: 80 }}
            variant="outlined"
            onClick={() => setConsignments(type)}
            {...(consignments === type
              ? {
                  variant: "filled",
                  color: "primary",
                  className: "gradient",
                }
              : {})}
          />
        ))}
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedConsignments;
