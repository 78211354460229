import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";
import PDF from "./PDF";
import { kebabCase } from "lodash";
import { Check, WarningOutlined } from "@mui/icons-material";

function GettingStartedProofOfTaxId({ initialState, nextStep, prevStep }) {
  const [businessUnits, setBusinessUnits] = useState(
    initialState.businessUnits || [],
  );
  const [lastProofOfTaxId, setLastProofOfTaxId] = useState(null);
  const [otherBusinessUnits, setOtherBusinessUnits] = useState([]);

  if (initialState.businessType === "group") {
    return (
      <GettingStartedLayout
        image="/sedan-blanco-2.png"
        nextStep={() => nextStep({ businessUnits })}
        prevStep={() => prevStep({ businessUnits })}
        disabled={businessUnits.some(
          (businessUnit) =>
            businessUnit.googlePlace && !businessUnit.proofOfTaxId,
        )}
        xl={6}
        lg={6}
        flexStart
      >
        <Box>
          <Typography variant="h5">
            Sube la Constancia de Situación Fiscal de cada Concesionario en PDF
          </Typography>
        </Box>
        <Box>
          <Paper variant="outlined">
            <List>
              {businessUnits
                .filter((businessUnit) => businessUnit.googlePlace)
                .map((businessUnit, idx) => (
                  <>
                    {idx > 0 && <Divider />}
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar
                          src={businessUnit.logo}
                          variant="rounded"
                          sx={{ boxShadow: 1 }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={businessUnit.businessName}
                        secondary={
                          businessUnit.proofOfTaxId ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Check
                                color="success"
                                sx={{ mr: 0.25, fontSize: 12 }}
                              />
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                Cargaste tu constancia
                              </Typography>
                            </Box>
                          ) : null
                        }
                      />
                      <ListItemSecondaryAction>
                        <PDF
                          url={businessUnit.proofOfTaxId}
                          onChange={(url) => {
                            setBusinessUnits(
                              businessUnits.map((item) =>
                                item.id === businessUnit.id
                                  ? { ...item, proofOfTaxId: url }
                                  : item,
                              ),
                            );

                            if (url) {
                              setLastProofOfTaxId(url);
                              setOtherBusinessUnits([]);
                            }
                          }}
                          filename={`csf-${kebabCase(
                            businessUnit.businessName,
                          )}.pdf`}
                          businessName={businessUnit.businessName}
                          variant="buttons"
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </>
                ))}
            </List>
          </Paper>
        </Box>
        <Typography variant="caption" color="textSecondary">
          Al cargar la Constancia de Situación Fiscal, y dar click en
          [Continuar], usted manifiesta de manera expresa e inequívoca que ha
          leído y está de acuerdo con los Términos y Condiciones del sitio, el
          Aviso de Privacidad, y el Contrato de Licencia de Acceso a la
          Plataforma.
        </Typography>

        <Dialog
          open={Boolean(lastProofOfTaxId)}
          fullWidth
          maxWidth="sm"
          onClose={() => setLastProofOfTaxId(null)}
        >
          <DialogTitle>
            ¿Quieres usar esta misma Constancia de Situación Fiscal para otros
            Concesionarios?
            <Typography sx={{ mt: 1 }}>Selecciona de la lista.</Typography>
          </DialogTitle>
          <List>
            {businessUnits
              .filter((businessUnit) => businessUnit.googlePlace)
              .map((businessUnit, idx) => {
                const selected = otherBusinessUnits.includes(businessUnit.id);

                return (
                  <>
                    {idx > 0 && <Divider />}
                    <ListItemButton
                      onClick={() =>
                        setOtherBusinessUnits(
                          selected
                            ? otherBusinessUnits.filter(
                                (item) => item !== businessUnit.id,
                              )
                            : [...otherBusinessUnits, businessUnit.id],
                        )
                      }
                    >
                      <Checkbox
                        edge="start"
                        checked={selected}
                        tabIndex={-1}
                        disableRipple
                      />
                      <ListItemText primary={businessUnit.businessName} />
                      {selected && businessUnit.proofOfTaxId && (
                        <ListItemSecondaryAction>
                          <Tooltip title="Se remplazará la Constancia de Situación Fiscal actual de este Concesionario.">
                            <WarningOutlined color="warning" />
                          </Tooltip>
                        </ListItemSecondaryAction>
                      )}
                    </ListItemButton>
                  </>
                );
              })}
          </List>
          <DialogActions>
            <Button color="inherit" onClick={() => setLastProofOfTaxId(false)}>
              No, gracias
            </Button>
            <Button
              variant="contained"
              disabled={otherBusinessUnits.length === 0}
              onClick={() => {
                setBusinessUnits(
                  businessUnits.map((item) =>
                    otherBusinessUnits.includes(item.id)
                      ? { ...item, proofOfTaxId: lastProofOfTaxId }
                      : item,
                  ),
                );
                setLastProofOfTaxId(null);
                setOtherBusinessUnits([]);
              }}
            >
              Usar Constancia
            </Button>
          </DialogActions>
        </Dialog>
      </GettingStartedLayout>
    );
  }

  return (
    <GettingStartedLayout
      image="/sedan-blanco-2.png"
      nextStep={() => nextStep({ businessUnits })}
      prevStep={() => prevStep({ businessUnits })}
      disabled={businessUnits.some(
        (businessUnit) =>
          businessUnit.googlePlace && !businessUnit.proofOfTaxId,
      )}
      xl={6}
      lg={6}
    >
      <Box>
        <Typography variant="h5">
          Sube la Constancia de Situación Fiscal de tu Concesionario
        </Typography>
        <Typography>Selecciona un archivo.</Typography>
      </Box>
      <Box>
        <PDF
          url={businessUnits[0].proofOfTaxId}
          onChange={(url) =>
            setBusinessUnits([{ ...businessUnits[0], proofOfTaxId: url }])
          }
          filename={`csf-${kebabCase(businessUnits[0].businessName)}.pdf`}
        />
      </Box>
      <Typography variant="caption" color="textSecondary">
        Al cargar la Constancia de Situación Fiscal, y dar click en [Continuar],
        usted manifiesta de manera expresa e inequívoca que ha leído y está de
        acuerdo con los Términos y Condiciones del sitio, el Aviso de
        Privacidad, y el Contrato de Licencia de Acceso a la Plataforma.
      </Typography>
    </GettingStartedLayout>
  );
}

export default GettingStartedProofOfTaxId;
