import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";
import { LightbulbOutlined } from "@mui/icons-material";
import BusinessIntelligenceDialog from "./BusinessIntelligenceDialog";

function GettingStartedDiscountOtherBrands({
  initialState,
  nextStep,
  prevStep,
}) {
  const [discountOtherBrands, setDiscountOtherBrands] = useState(
    initialState.discountOtherBrands || "",
  );

  const businessUnitsWithBrands = initialState.businessUnits
    .filter((businessUnit) => businessUnit.googlePlace)
    .some((businessUnit) => !businessUnit.noOwnBrands);

  return (
    <GettingStartedLayout
      image="/market-intelligence.webp"
      noImageTransformations
      flexStart
      noBackgroundLogo
      fullWidthImage
      nextStep={() => nextStep({ discountOtherBrands })}
      prevStep={() =>
        prevStep({ discountOtherBrands }, businessUnitsWithBrands ? 1 : 2)
      }
      disabled={!discountOtherBrands || !discountOtherBrands.trim()}
    >
      <Box>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {!businessUnitsWithBrands
            ? "A partir del valor promedio de mercado, ¿qué porcentaje de descuento haces para comprar autos?"
            : "Y para autos de otras marcas, en promedio, ¿qué porcentaje de descuento haces?"}
          <BusinessIntelligenceDialog trigger="button" />
        </Typography>
        <Typography>
          Define un porcentaje base, en el siguiente paso podrás hacer ajustes
          individuales por cada marca.
        </Typography>
      </Box>
      <Box>
        <TextField
          variant="outlined"
          autoFocus
          value={discountOtherBrands}
          type="number"
          onChange={(e) => setDiscountOtherBrands(e.target.value)}
          onKeyDown={(e) =>
            e.key === "Enter" &&
            discountOtherBrands &&
            discountOtherBrands.trim()
              ? nextStep({ discountOtherBrands })
              : null
          }
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            componentsProps: {
              input: {
                min: 0,
                max: 100,
              },
            },
          }}
          placeholder="Ej. 15"
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <LightbulbOutlined color="action" sx={{ mr: 1 }} />
        <Typography variant="body2" color="textSecondary">
          Lo más común es trabajar con un descuento de entre el 14% y 17%,&nbsp;
          <BusinessIntelligenceDialog trigger="conoce por qué" />.
        </Typography>
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedDiscountOtherBrands;
