import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Hidden,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

function GettingStartedDone({ initialState, prevStep }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [goingBack, setGoingBack] = useState(false);

  useEffect(() => {
    if (!goingBack && searchParams.has("reschedule")) {
      setGoingBack(true);
      setSearchParams({});
      prevStep({ rescheduling: true });
    }
  }, [searchParams, goingBack, prevStep, setGoingBack, setSearchParams]);

  if (initialState.calendarEvent) {
    const date = moment.tz(
      initialState.calendarEvent.start.dateTime,
      initialState.calendarEvent.start.timeZone,
    );

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box>
          <Typography variant="h4" sx={{ mt: 10 }}>
            Nos vemos en tu&nbsp;
            <Box
              component="span"
              sx={(theme) => ({
                color: theme.palette.secondary.main,
              })}
              className="gradient gradient--text"
            >
              cita de verificación
            </Box>
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            backgroundImage:
              "linear-gradient(to right, black 0%, #434343 100%)",
            px: 3,
            py: 3,
            textAlign: "center",
            [theme.breakpoints.up("md")]: {
              textAlign: "left",
              px: 8,
              py: 6,
            },
            mt: 6,
          })}
          maxWidth="800px"
          width="100%"
          borderRadius={4}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Stack spacing={4}>
                <Typography variant="h6" color="white">
                  Está programada para el&nbsp;
                  <Box
                    component="span"
                    sx={(theme) => ({
                      color: theme.palette.secondary.main,
                    })}
                  >
                    {date.format("dddd")}
                  </Box>
                  {`, ${date.format("D")} de ${date.format("MMMM")}, a las `}
                  <Box
                    component="span"
                    sx={(theme) => ({
                      color: theme.palette.secondary.main,
                    })}
                  >
                    {date.format("hh:mm a")}
                  </Box>
                  .
                </Typography>
                <div>
                  <Button
                    className="gradient"
                    variant="contained"
                    size="large"
                    component="a"
                    href={initialState.calendarEvent.hangoutLink}
                  >
                    Únete a la reunión
                  </Button>
                </div>
                <Typography color="white" variant="body2">
                  Si lo requieres, puedes{" "}
                  {goingBack ? (
                    <CircularProgress size={14} />
                  ) : (
                    <Link
                      onClick={() => {
                        setGoingBack(true);
                        prevStep({ rescheduling: true });
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      reagendar tu cita.
                    </Link>
                  )}
                </Typography>
              </Stack>
            </Grid>
            <Hidden mdDown>
              <Grid item md={4}>
                <Box
                  component="img"
                  src="/v.svg"
                  alt=""
                  sx={{
                    width: 100,
                    height: 100,
                    transform: "scale(2.8) translateX(50px) translateY(25px)",
                    filter:
                      "drop-shadow(0 4px 3px #00000032) drop-shadow(0 2px 2px #0000000f)",
                  }}
                />
              </Grid>
            </Hidden>
          </Grid>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box>
        <Typography variant="h4" sx={{ mt: 10 }}>
          <Box
            component="span"
            sx={(theme) => ({
              color: theme.palette.secondary.main,
            })}
            className="gradient gradient--text"
          >
            Listo
          </Box>
          &nbsp;¡Terminaste de configurar tu negocio!
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          backgroundImage: "linear-gradient(to right, black 0%, #434343 100%)",
          px: 3,
          py: 3,
          textAlign: "center",
          [theme.breakpoints.up("md")]: {
            textAlign: "left",
            px: 8,
            py: 6,
          },
          mt: 6,
        })}
        maxWidth="800px"
        width="100%"
        borderRadius={4}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" color="white" sx={{ mb: 4 }}>
              Tu cita para verificación
              <br />
              fue&nbsp;
              <Box
                component="span"
                sx={(theme) => ({
                  color: theme.palette.secondary.main,
                })}
              >
                agendada con éxito
              </Box>
              .
            </Typography>
            <Typography color="white">
              Te mandamos un correo de confirmación para confirmar tu cita de
              verificación de negocio, donde finalizaremos tu proceso de
              afiliación. ¡Nos vemos pronto!
            </Typography>
          </Grid>
          <Hidden mdDown>
            <Grid item md={4}>
              <Box
                component="img"
                src="/v.svg"
                alt=""
                sx={{
                  width: 100,
                  height: 100,
                  transform: "scale(2.8) translateX(50px) translateY(25px)",
                  filter:
                    "drop-shadow(0 4px 3px #00000032) drop-shadow(0 2px 2px #0000000f)",
                }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Box>
    </Box>
  );
}

export default GettingStartedDone;
