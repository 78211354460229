import { gql, useQuery } from "@apollo/client";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  Link,
  ListSubheader,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Search } from "@mui/icons-material";
import { v4 as uuid } from "uuid";
import { GroupedVirtuoso } from "react-virtuoso";

import useLocalStorage from "../hooks/useLocalStorage";
import Rule from "./Rule";
import GettingStartedLayout from "./GettingStartedLayout";

const GET_BRANDS = gql`
  query GetBrands($type: String, $parents: [ID]) {
    brands: entities(type: $type, parents: $parents) {
      id
      name
      attributes {
        name
        value
      }
    }
  }
`;

function GettingStartedConsignmentsRules({
  initialState,
  nextStep,
  prevStep,
  saveState,
}) {
  const [authToken] = useLocalStorage("authToken", null);
  const [rules, setRules] = useState(initialState.consignmentsRules || []);
  const [search, setSearch] = useState("");
  const [mode, setMode] = useState(initialState.consignmentsMode || "basic");
  const [saving, setSaving] = useState(null);

  const { loading } = useQuery(GET_BRANDS, {
    context: { headers: { Authorization: ["Bearer", authToken].join(" ") } },
    variables: {
      type: "brand",
      parents: ["5cdc482fe9b82b87f832bcf7"],
    },
    onCompleted({ brands }) {
      if (rules.length === 0) {
        setRules(
          brands.map((brand) => ({
            id: uuid(),
            brand,
            noOffer: false,
            specificRules: [],
          })),
        );
      }
    },
  });

  const filteredRules = rules.filter(
    (rule) =>
      !search ||
      !search.trim() ||
      rule.brand.name.toLowerCase().includes(search.trim().toLowerCase()),
  );

  return (
    <GettingStartedLayout
      lg={mode === "advanced" ? 6 : 5}
      xl={mode === "advanced" ? 5 : 4}
      image="/sedan-negro.png"
      nextStep={() =>
        nextStep({ consignmentsRules: rules, consignmentsMode: mode })
      }
      prevStep={() =>
        prevStep({ consignmentsRules: rules, consignmentsMode: mode })
      }
      disabled={false}
    >
      <Box>
        <Typography variant="h5">Configura tus consignaciones</Typography>
        <Typography>
          Excluye las marcas o modelos específicos que no te interesa aceptar
          para consignación.
        </Typography>
      </Box>
      <Box>
        <TextField
          variant="outlined"
          fullWidth
          autoFocus
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          placeholder="Buscar marcas"
        />
      </Box>
      <Box>
        {loading && <CircularProgress size={64} />}

        {!loading && (
          <GroupedVirtuoso
            groupCounts={[rules.length]}
            style={{ height: 520 }}
            groupContent={() => <ListSubheader>Marcas</ListSubheader>}
            itemContent={(idx, groupIdx) => {
              const rule = filteredRules[idx];

              if (!rule) return;

              return (
                <Rule
                  key={rule.id}
                  rule={rule}
                  onChange={async (newRule) => {
                    const newRules = rules.map((oldRule) =>
                      oldRule.id === rule.id ? newRule : oldRule,
                    );
                    await saveState({ consignmentsRules: newRules });
                    setRules(newRules);
                  }}
                  mode={mode}
                  saving={saving}
                  setSaving={setSaving}
                  variant="consignments"
                />
              );
            }}
          />
        )}
      </Box>
      <Box>
        <FormControlLabel
          control={
            saving === "mode" ? (
              <CircularProgress size={24} sx={{ mx: 2.125 }} />
            ) : (
              <Switch
                checked={mode === "advanced"}
                onChange={async (e) => {
                  setSaving("mode");
                  const newMode = e.target.checked ? "advanced" : "basic";
                  setMode(newMode);
                  await saveState({ consignmentsMode: newMode });
                  setSaving(null);
                }}
                disabled={Boolean(saving)}
              />
            )
          }
          label={
            <Box>
              <Typography variant="body2">
                Especificar excepciones por modelo
              </Typography>
            </Box>
          }
        />
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedConsignmentsRules;
