import { useCallback, useEffect, useMemo, useState } from "react";

const CUSTOM_STORAGE_EVENT_TYPE = "localStorage";

function writeLocalStorage(key, value) {
  localStorage.setItem(key, value || "null");
  window.dispatchEvent(
    new CustomEvent(CUSTOM_STORAGE_EVENT_TYPE, { detail: { key, value } })
  );
}

export default function useLocalStorage(key, defaultValue) {
  const [jsonValue, setJsonValue] = useState(
    localStorage.getItem(key) || JSON.stringify(defaultValue)
  );

  useEffect(() => writeLocalStorage(key, jsonValue), [key, jsonValue]);

  const onStorageChange = useCallback(
    (e) => {
      if (e.key === key) setJsonValue(e.newValue || "null");
    },
    [key]
  );

  const onCustomStorageChange = useCallback(
    (e) => {
      if (e.detail.key === key) setJsonValue(e.detail.value || "null");
    },
    [key]
  );

  useEffect(() => {
    const eventListener = (e) => onStorageChange(e);
    window.addEventListener("storage", eventListener);

    const customEventListener = (e) =>
      onCustomStorageChange({
        detail: { key: e.detail.key, value: e.detail.value },
      });
    window.addEventListener(CUSTOM_STORAGE_EVENT_TYPE, customEventListener);

    return () => {
      window.removeEventListener("storage", eventListener);
      window.removeEventListener(
        CUSTOM_STORAGE_EVENT_TYPE,
        customEventListener
      );
    };
  }, [onCustomStorageChange, onStorageChange]);

  const value = useMemo(() => JSON.parse(jsonValue || "null"), [jsonValue]);

  const setValue = useCallback((value) => {
    setJsonValue(JSON.stringify(value));
  }, []);

  return [value, setValue];
}
