import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";

function GettingStartedFreeformRules({ initialState, nextStep, prevStep }) {
  const [freeformRules, setFreeformRules] = useState(
    initialState.freeformRules || "",
  );

  return (
    <GettingStartedLayout
      image="/sedan-blanco-2.png"
      nextStep={() => nextStep({ freeformRules })}
      prevStep={() =>
        prevStep({ freeformRules }, initialState.consignments ? 1 : 6)
      }
    >
      <Box>
        <Typography variant="h5">¿Algo más?</Typography>
        <Typography>
          Si piensas que no pudiste describir por completo todas tus posibles
          ofertas de compra o consignación, o que estas no representan
          exactamente tu manera de operar, escribe aquí tus reglas y nuestra
          I.A. + nuestro equipo de éxito del cliente se engarcará de
          configurarlas por ti.
        </Typography>
      </Box>
      <Box>
        <TextField
          variant="outlined"
          fullWidth
          autoFocus
          value={freeformRules}
          onChange={(e) => setFreeformRules(e.target.value)}
          multiline
          minRows={10}
          placeholder={`Ejemplos:

A los autos del 2016 hacia atrás les hago un 2% de descuento adicional.

No compro autos con transimisión manual.`}
        />
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedFreeformRules;
