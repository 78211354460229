import { EditOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import moment from "moment";
import { TimePicker } from "@mui/x-date-pickers";

function Schedule({ schedule, onChange }) {
  const [editing, setEditing] = useState(false);
  const [newWeekdays, setNewWeekdays] = useState([]);
  const [newClosed, setNewClosed] = useState(null);
  const [newFromHours, setNewFromHours] = useState(null);
  const [newFromMinutes, setNewFromMinutes] = useState(null);
  const [newToHours, setNewToHours] = useState(null);
  const [newToMinutes, setNewToMinutes] = useState(null);

  function handleChange() {
    setEditing(false);

    if (newClosed) {
      const newSchedule = schedule.filter(
        (period) => !newWeekdays.includes(period.weekdays[0]),
      );
      onChange(newSchedule);
      return;
    }

    const newSchedule = [
      ...schedule.map((period) =>
        newWeekdays.includes(period.weekdays[0])
          ? {
              ...period,
              fromHours: newFromHours,
              fromMinutes: newFromMinutes,
              toHours: newToHours,
              toMinutes: newToMinutes,
            }
          : period,
      ),
      ...newWeekdays.map((weekday) => ({
        weekdays: [weekday],
        fromHours: newFromHours,
        fromMinutes: newFromMinutes,
        toHours: newToHours,
        toMinutes: newToMinutes,
      })),
    ].filter(
      (item, idx, arr) =>
        arr.findIndex((el) => el.weekdays[0] === item.weekdays[0]) === idx,
    );
    onChange(newSchedule);
  }

  return (
    <>
      <List sx={{ maxWidth: 360 }}>
        {[
          { weekday: 1, name: "Lunes" },
          { weekday: 2, name: "Martes" },
          { weekday: 3, name: "Miércoles" },
          { weekday: 4, name: "Jueves" },
          { weekday: 5, name: "Viernes" },
          { weekday: 6, name: "Sábado" },
          { weekday: 0, name: "Domingo" },
        ].map((day) => {
          const period = schedule.find(
            (item) => item.weekdays[0] === day.weekday,
          );
          return (
            <ListItem key={day.weekday} disableGutters>
              <ListItemText primary={day.name} />
              <ListItemSecondaryAction>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="body2">
                    {period
                      ? [
                          moment()
                            .hours(period.fromHours)
                            .minutes(period.fromMinutes)
                            .format("hh:mma"),
                          moment()
                            .hours(period.toHours)
                            .minutes(period.toMinutes)
                            .format("hh:mma"),
                        ].join(" - ")
                      : "Cerrado"}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setEditing(true);
                      setNewWeekdays([day.weekday]);
                      setNewClosed(!period);
                      setNewFromHours(period ? period.fromHours : null);
                      setNewFromMinutes(period ? period.fromMinutes : null);
                      setNewToHours(period ? period.toHours : null);
                      setNewToMinutes(period ? period.toMinutes : null);
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                </Box>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>

      <Dialog
        open={editing}
        fullWidth
        maxWidth="xs"
        onClose={() => setEditing(false)}
      >
        <DialogTitle sx={{ textAlign: "center" }}>Días y horas</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              {[
                { weekday: 1, name: "Lu" },
                { weekday: 2, name: "Ma" },
                { weekday: 3, name: "Mi" },
                { weekday: 4, name: "Ju" },
                { weekday: 5, name: "Vi" },
                { weekday: 6, name: "Sa" },
                { weekday: 0, name: "Do" },
              ].map((day) => (
                <ButtonBase
                  sx={{
                    width: 40,
                    height: 40,
                    border: newWeekdays.includes(day.weekday)
                      ? "1px solid transparent"
                      : "1px solid #ccc",
                    borderRadius: 20,
                    mx: 0.5,
                  }}
                  {...(newWeekdays.includes(day.weekday)
                    ? { className: "gradient" }
                    : {})}
                  onClick={() =>
                    setNewWeekdays(
                      newWeekdays.includes(day.weekday)
                        ? newWeekdays.filter(
                            (weekday) => weekday !== day.weekday,
                          )
                        : [...newWeekdays, day.weekday],
                    )
                  }
                >
                  <Typography
                    {...(newWeekdays.includes(day.weekday)
                      ? { color: "white" }
                      : {})}
                  >
                    {day.name}
                  </Typography>
                </ButtonBase>
              ))}
            </Box>

            <Box sx={{ pl: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newWeekdays.length === 7}
                    onChange={(e) =>
                      setNewWeekdays(
                        e.target.checked ? [1, 2, 3, 4, 5, 6, 0] : [],
                      )
                    }
                  />
                }
                label="Todos los días"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newClosed}
                    onChange={(e) => setNewClosed(e.target.checked)}
                    color="error"
                  />
                }
                label="Cerrado"
              />
            </Box>

            {!newClosed && (
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TimePicker
                    label="Abre"
                    value={
                      newFromHours !== null && newFromMinutes !== null
                        ? moment().hours(newFromHours).minutes(newFromMinutes)
                        : null
                    }
                    onChange={(newValue) => {
                      if (newValue && newValue.isValid()) {
                        setNewFromHours(newValue.hours());
                        setNewFromMinutes(newValue.minutes());
                      } else {
                        setNewFromHours(null);
                        setNewFromMinutes(null);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    label="Cierra"
                    value={
                      newToHours !== null && newToMinutes !== null
                        ? moment().hours(newToHours).minutes(newToMinutes)
                        : null
                    }
                    onChange={(newValue) => {
                      if (newValue && newValue.isValid()) {
                        setNewToHours(newValue.hours());
                        setNewToMinutes(newValue.minutes());
                      } else {
                        setNewToHours(null);
                        setNewToMinutes(null);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => setEditing(false)}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleChange}
            disabled={
              newWeekdays.length === 0 ||
              (!newClosed &&
                [newFromHours, newFromMinutes, newToHours, newToMinutes].some(
                  (item) => item === null,
                ))
            }
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Schedule;
