import { useEffect } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";

function Redirector() {
  const navigate = useNavigate();
  const [authToken] = useLocalStorage("authToken", null);

  useEffect(() => {
    if (authToken) {
      navigate("/getting-started");
      return;
    }

    navigate("/request-access");
  }, [authToken, navigate]);

  return <></>;
}

export default Redirector;
