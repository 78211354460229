import { DirectionsCar, Store } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { v4 as uuid } from "uuid";

import BusinessUnitSchedule from "./BusinessUnitSchedule";
import BusinessUnitLogo from "./BusinessUnitLogo";
import BusinessUnitStorefront from "./BusinessUnitStorefront";
import BusinessUnitGooglePlace from "./BusinessUnitGooglePlace";
import BusinessUnitOwnBrands from "./BusinessUnitOwnBrands";
import BusinessUnitInventory from "./BusinessUnitInventory";
import { isURL } from "validator";
import { useSnackbar } from "notistack";
import BusinessUnitUsers from "./BusinessUnitUsers";

function BusinessUnit({
  businessUnit: businessUnitInput,
  onChange,
  variant,
  prevStep,
  nextStep,
  firstStep,
  ownProfile,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(null);
  const [activeStep, setActiveStep] = useState(firstStep || 0);
  const [businessUnit, setBusinessUnitState] = useState(businessUnitInput);
  const {
    businessName,
    ownBrands,
    noOwnBrands,
    search: searchInput,
    places = [],
    googlePlace,
    schedule,
    storefront,
    logo,
    inventoryUrl,
    mlSeller,
    idealInventorySize,
    users,
  } = businessUnit;
  const search = searchInput || businessName;

  function setBusinessUnit(data) {
    setBusinessUnitState(data);
    onChange(data);
  }

  return (
    <Stepper orientation="vertical" activeStep={activeStep}>
      {/* businessName */}
      {variant === "dialog" && (
        <Step>
          <StepLabel>Nombre comercial</StepLabel>
          <StepContent>
            <Stack spacing={3}>
              <Typography>
                Este es el nombre que verán los vendedores particulares.
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                autoFocus
                value={businessName}
                onChange={(e) =>
                  setBusinessUnit({
                    ...businessUnit,
                    businessName: e.target.value,
                  })
                }
                onKeyDown={(e) =>
                  e.key === "Enter" &&
                  businessUnit.businessName &&
                  businessUnit.businessName.trim()
                    ? setActiveStep(1)
                    : null
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Store />
                    </InputAdornment>
                  ),
                }}
              />
              <Box>
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => setActiveStep(activeStep + 1)}
                  {...(businessUnit.businessName &&
                  businessUnit.businessName.trim()
                    ? { className: "gradient" }
                    : { disabled: true })}
                >
                  Siguiente
                </Button>
                <Button
                  size="large"
                  variant="text"
                  onClick={() => setActiveStep(activeStep - 1)}
                  color="inherit"
                >
                  Regresar
                </Button>
              </Box>
            </Stack>
          </StepContent>
        </Step>
      )}

      {/* own brands */}
      <Step>
        <StepLabel>Marcas</StepLabel>
        <StepContent>
          <Stack spacing={3}>
            <BusinessUnitOwnBrands
              ownBrands={ownBrands}
              noOwnBrands={noOwnBrands}
              businessName={businessName}
              onChange={(changes) =>
                setBusinessUnit({ ...businessUnit, ...changes })
              }
            />
            <Box>
              <Button
                size="large"
                variant="contained"
                onClick={() => {
                  if (noOwnBrands)
                    setBusinessUnit({ ...businessUnit, ownBrands: [] });
                  setActiveStep(activeStep + 1);
                }}
                {...(noOwnBrands || (ownBrands && ownBrands.length > 0)
                  ? { className: "gradient" }
                  : { disabled: true })}
                sx={{ mr: 1 }}
              >
                Siguiente
              </Button>
              <Button
                size="large"
                variant="text"
                onClick={async () => {
                  if (noOwnBrands)
                    setBusinessUnit({ ...businessUnit, ownBrands: [] });
                  setSaving("prev");
                  prevStep ? await prevStep() : setActiveStep(activeStep - 1);
                  setSaving(null);
                }}
                color="inherit"
                disabled={saving === "prev"}
              >
                {saving === "prev" ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Regresar"
                )}
              </Button>
            </Box>
          </Stack>
        </StepContent>
      </Step>

      {/* google place */}
      <Step>
        <StepLabel>Ubicación</StepLabel>
        <StepContent>
          <Stack spacing={3}>
            <BusinessUnitGooglePlace
              search={search}
              googlePlace={googlePlace}
              places={places}
              businessName={businessName}
              onChange={(changes) =>
                setBusinessUnit({ ...businessUnit, ...changes })
              }
              variant={variant}
            />
            <Box>
              <Button
                size="large"
                variant="contained"
                onClick={() => setActiveStep(activeStep + 1)}
                {...(googlePlace &&
                places.some(
                  (place) => place.place_id === googlePlace.place_id,
                ) &&
                !saving
                  ? { className: "gradient" }
                  : { disabled: true })}
                sx={{ mr: 1 }}
              >
                Siguiente
              </Button>
              <Button
                size="large"
                variant="text"
                onClick={() => setActiveStep(activeStep - 1)}
                color="inherit"
              >
                Regresar
              </Button>
            </Box>
          </Stack>
        </StepContent>
      </Step>

      {/* schedules */}
      <Step>
        <StepLabel>Horarios de inspección</StepLabel>
        <StepContent>
          <Stack spacing={3}>
            <BusinessUnitSchedule
              schedule={schedule}
              googlePlace={googlePlace}
              onChange={(newSchedule) =>
                setBusinessUnit({ ...businessUnit, schedule: newSchedule })
              }
            />
            <Box>
              <Button
                size="large"
                variant="contained"
                onClick={() => setActiveStep(activeStep + 1)}
                {...(schedule && schedule.length > 0
                  ? { className: "gradient" }
                  : { disabled: true })}
                sx={{ mr: 1 }}
              >
                Siguiente
              </Button>
              <Button
                size="large"
                variant="text"
                onClick={() => setActiveStep(activeStep - 1)}
                color="inherit"
              >
                Regresar
              </Button>
            </Box>
          </Stack>
        </StepContent>
      </Step>

      {/* storefront */}
      <Step>
        <StepLabel>Fotografía de la fachada</StepLabel>
        <StepContent>
          <Stack spacing={3}>
            <BusinessUnitStorefront
              storefront={storefront}
              googlePlace={googlePlace}
              onChange={(newStorefront) =>
                setBusinessUnit({
                  ...businessUnit,
                  storefront: newStorefront,
                })
              }
              variant={variant}
            />
            <Box>
              <Button
                size="large"
                variant="contained"
                onClick={() => setActiveStep(activeStep + 1)}
                {...(businessUnit.storefront &&
                !businessUnit.storefront.includes("blob:http")
                  ? { className: "gradient" }
                  : { disabled: true })}
                sx={{ mr: 1 }}
              >
                Siguiente
              </Button>
              <Button
                size="large"
                variant="text"
                onClick={() => setActiveStep(activeStep - 1)}
                color="inherit"
              >
                Regresar
              </Button>
            </Box>
          </Stack>
        </StepContent>
      </Step>

      {/* logo */}
      <Step>
        <StepLabel>Logotipo</StepLabel>
        <StepContent>
          <Stack spacing={3}>
            <BusinessUnitLogo
              logo={logo}
              ownBrands={ownBrands}
              onChange={(newLogo) =>
                setBusinessUnit({ ...businessUnit, logo: newLogo })
              }
            />
            <Box>
              <Button
                variant="contained"
                onClick={() => setActiveStep(activeStep + 1)}
                size="large"
                {...(businessUnit.logo
                  ? { className: "gradient" }
                  : { disabled: true })}
                sx={{ mr: 1 }}
              >
                Siguiente
              </Button>

              <Button
                size="large"
                variant="text"
                onClick={() => setActiveStep(activeStep - 1)}
                color="inherit"
                disabled={Boolean(saving)}
              >
                Regresar
              </Button>
            </Box>
          </Stack>
        </StepContent>
      </Step>

      {/* ideal inventory */}
      <Step>
        <StepLabel>Inventario ideal</StepLabel>
        <StepContent>
          <Stack spacing={3}>
            <Typography>
              En un escenario ideal, ¿cuántas unidades quieres tener
              simultáneamente en tu inventario?
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              autoFocus
              value={idealInventorySize}
              type="number"
              onChange={(e) =>
                setBusinessUnit({
                  ...businessUnit,
                  idealInventorySize: e.target.value,
                })
              }
              onKeyDown={(e) =>
                e.key === "Enter" &&
                idealInventorySize &&
                Number.parseInt(idealInventorySize, 10) > 0
                  ? setActiveStep(activeStep + 1)
                  : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DirectionsCar />
                  </InputAdornment>
                ),
                componentsProps: {
                  input: {
                    min: 0,
                  },
                },
                sx: {
                  backgroundColor: "white",
                  maxWidth: 300,
                },
              }}
            />
            <Box>
              <Button
                size="large"
                variant="contained"
                onClick={() => setActiveStep(activeStep + 1)}
                {...(idealInventorySize &&
                Number.parseInt(idealInventorySize, 10) > 0
                  ? { className: "gradient" }
                  : { disabled: true })}
              >
                Siguiente
              </Button>
              <Button
                size="large"
                variant="text"
                onClick={() => setActiveStep(activeStep - 1)}
                color="inherit"
              >
                Regresar
              </Button>
            </Box>
          </Stack>
        </StepContent>
      </Step>

      {/* current inventory */}
      <Step>
        <StepLabel>Inventario actual</StepLabel>
        <StepContent>
          <Stack spacing={3}>
            <BusinessUnitInventory
              inventoryUrl={inventoryUrl}
              mlSeller={mlSeller}
              googlePlace={googlePlace}
              onChange={(changes) =>
                setBusinessUnit({ ...businessUnit, ...changes })
              }
              variant={variant}
            />
            <Box>
              <Button
                variant="contained"
                onClick={async () => {
                  const validInventoryUrl =
                    inventoryUrl && isURL(inventoryUrl.trim());
                  if (!mlSeller && !validInventoryUrl) {
                    enqueueSnackbar(
                      "Debes identificar tu cuenta de Mercado Libre o ingresar la URL de la sección de inventario de tu sitio web.",
                      { variant: "error" },
                    );
                    return;
                  }

                  if (variant === "settings") {
                    setSaving("next");
                    await nextStep();
                    setSaving(null);
                  } else {
                    setActiveStep(activeStep + 1);
                  }
                }}
                size="large"
                {...(!saving ? { className: "gradient" } : { disabled: true })}
                sx={{ mr: 1 }}
              >
                {saving === "next" ? (
                  <CircularProgress size={24} />
                ) : variant === "settings" ? (
                  "Guardar"
                ) : (
                  "Siguiente"
                )}
              </Button>

              <Button
                size="large"
                variant="text"
                onClick={() => setActiveStep(activeStep - 1)}
                color="inherit"
                disabled={Boolean(saving)}
              >
                Regresar
              </Button>
            </Box>
          </Stack>
        </StepContent>
      </Step>

      {/* users */}
      {variant !== "settings" && (
        <Step>
          <StepLabel>Usuarios</StepLabel>
          <StepContent>
            <Stack spacing={3}>
              <BusinessUnitUsers
                users={users || []}
                defaultUsers={[
                  {
                    id: uuid(),
                    name: ownProfile.name,
                    email: ownProfile.email,
                    roles: ["settings"],
                    locked: true,
                  },
                ]}
                onChange={(changes) =>
                  setBusinessUnit({ ...businessUnit, ...changes })
                }
              />
              <Box>
                <Button
                  variant="contained"
                  onClick={async () => {
                    if (!users.some((user) => user.roles.includes("crm"))) {
                      return enqueueSnackbar(
                        "Debes agregar al menos a un asesor de servicio",
                        { variant: "error" },
                      );
                    }

                    if (
                      !users.some((user) => user.roles.includes("inspection"))
                    ) {
                      return enqueueSnackbar(
                        "Debes agregar al menos a un valuador",
                        { variant: "error" },
                      );
                    }

                    if (
                      !users.some((user) => user.roles.includes("settings"))
                    ) {
                      return enqueueSnackbar(
                        "Debes agregar al menos a un administrador",
                        { variant: "error" },
                      );
                    }

                    setSaving("next");
                    await nextStep();
                    setSaving(null);
                  }}
                  size="large"
                  {...(!saving
                    ? { className: "gradient" }
                    : { disabled: true })}
                  sx={{ mr: 1 }}
                >
                  {saving === "next" ? (
                    <CircularProgress size={24} />
                  ) : variant === "dialog" ? (
                    "Guardar"
                  ) : (
                    "Siguiente"
                  )}
                </Button>

                <Button
                  size="large"
                  variant="text"
                  onClick={() => setActiveStep(activeStep - 1)}
                  color="inherit"
                  disabled={Boolean(saving)}
                >
                  Regresar
                </Button>
              </Box>
            </Stack>
          </StepContent>
        </Step>
      )}
    </Stepper>
  );
}

export default BusinessUnit;
