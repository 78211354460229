import { Box, Button, ButtonGroup, TextField, Typography } from "@mui/material";
import { useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";

function GettingStartedInspectionDuration({ initialState, nextStep, prevStep }) {
  const [inspectionDurationUnit, setInspectionDurationUnit] = useState(
    initialState.inspectionDurationUnit || "hours"
  );

  const [inspectionDuration, setInspectionDuration] = useState(
    initialState.inspectionDuration || null
  );

  const [error, setError] = useState(null);

  return (
    <GettingStartedLayout
      image="/sedan-rojo.png"
      nextStep={() => {
        if (inspectionDurationUnit === 'minutes' && inspectionDuration < 15) {
          setError('La duración mínima es de 15 minutos. Considera que debes apartar el timpo suficiente para que, durante la inspección, logres concretar la compra de la unidad.')
          return 'stop';
        }

        return nextStep({ inspectionDuration, inspectionDurationUnit })
      }}
      prevStep={() => prevStep({ inspectionDuration, inspectionDurationUnit })}
      disabled={!inspectionDuration}
    >
      <Box>
        <Typography variant="h5">
          Muy bien, y ¿cuánto dura una inspección?
        </Typography>
        <Typography>
          Reservaremos bloques de esa duración en tu agenda cuando un vendedor particular agende una inspección. Indica si quieres expresarlo en horas o minutos.
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItem: "center" }}>
        <TextField
          variant="outlined"
          autoFocus
          type="number"
          value={inspectionDuration}
          onChange={(e) => setInspectionDuration(e.target.value.replace(/\D/g, ""))}
          onKeyDown={(e) =>
            e.key === "Enter" && inspectionDuration
              ? nextStep({ inspectionDuration, inspectionDurationUnit })
              : null
          }
          sx={{ mr: 1, width: 80 }}
          InputProps={{
            componentsProps: {
              input: {
                min: inspectionDurationUnit === 'hours' ? 1 : 15,
              },
            },
          }}
          placeholder="Ej. 1"
          error={Boolean(error)}
        />
        <ButtonGroup>
          <Button
            variant={inspectionDurationUnit === "hours" ? "contained" : "outlined"}
            onClick={() => setInspectionDurationUnit("hours")}
          >
            {inspectionDuration === '1' ? 'Hora' : 'Horas'}
          </Button>
          <Button
            variant={inspectionDurationUnit === "minutes" ? "contained" : "outlined"}
            onClick={() => setInspectionDurationUnit("minutes")}
          >
            {inspectionDuration === '1' ? 'Minuto' : 'Minutos'}
          </Button>
        </ButtonGroup>
      </Box>
      {error && <Typography color={error ? 'error' : 'inherit'}>{error}</Typography>}
    </GettingStartedLayout>
  );
}

export default GettingStartedInspectionDuration;
