import {
  Box,
  Chip,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";
import toNumber from "../utils/toNumber";

function GettingStartedConsignmentsMaxKms({
  initialState,
  nextStep,
  prevStep,
}) {
  const [consignmentsNoMaxKms, setConsignmentsNoMaxKms] = useState(
    typeof initialState.consignmentsNoMaxKms !== "undefined" &&
      initialState.consignmentsNoMaxKms !== null
      ? initialState.consignmentsNoMaxKms
      : null,
  );

  const [consignmentsMaxKms, setConsignmentsMaxKms] = useState(
    typeof initialState.consignmentsMaxKms !== "undefined" &&
      initialState.consignmentsMaxKms !== null
      ? initialState.consignmentsMaxKms
      : null,
  );

  return (
    <GettingStartedLayout
      image="/sedan-negro.png"
      nextStep={() => nextStep({ consignmentsNoMaxKms, consignmentsMaxKms })}
      prevStep={() => prevStep({ consignmentsNoMaxKms, consignmentsMaxKms })}
      disabled={
        consignmentsNoMaxKms === null ||
        (!consignmentsNoMaxKms && !consignmentsMaxKms)
      }
    >
      <Box>
        <Typography variant="h5">
          ¿Tienes algún limitante para el kilometraje en autos que aceptas para
          consignación?
        </Typography>
        <Typography>Selecciona una opción.</Typography>
      </Box>
      <Box>
        {[
          { type: false, label: "Si" },
          { type: true, label: "No" },
        ].map(({ type, label }) => (
          <Chip
            key={label}
            label={label}
            sx={{ mr: 1, mb: 1, minWidth: 80 }}
            variant="outlined"
            onClick={() => setConsignmentsNoMaxKms(type)}
            {...(consignmentsNoMaxKms === type
              ? {
                  variant: "filled",
                  color: "primary",
                  className: "gradient",
                }
              : {})}
          />
        ))}
      </Box>

      {consignmentsNoMaxKms === false && (
        <Box>
          <Typography sx={{ mb: 2 }}>
            ¿A partir de qué kilometraje no te interesa recibir autos para
            consignación?
          </Typography>

          <TextField
            variant="outlined"
            autoFocus
            value={consignmentsMaxKms ? toNumber(consignmentsMaxKms) : ""}
            onChange={(e) =>
              setConsignmentsMaxKms(e.target.value.replace(/\D/g, ""))
            }
            onKeyDown={(e) =>
              e.key === "Enter" &&
              consignmentsMaxKms &&
              consignmentsMaxKms.trim()
                ? nextStep({ consignmentsNoMaxKms, consignmentsMaxKms })
                : null
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">km</InputAdornment>,
              componentsProps: {
                input: {
                  min: 0,
                },
              },
            }}
            placeholder="Ej. 150,000"
          />
        </Box>
      )}
    </GettingStartedLayout>
  );
}

export default GettingStartedConsignmentsMaxKms;
