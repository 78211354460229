import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";
import { Search } from "@mui/icons-material";
import { GroupedVirtuoso } from "react-virtuoso";
import { gql, useQuery } from "@apollo/client";
import useLocalStorage from "../hooks/useLocalStorage";

const GET_ALL_MODELS = gql`
  query GetAllModels {
    allModels {
      modelId
      modelName
      brandName
    }
  }
`;

function GettingStartedOverstockExceptions({
  initialState,
  nextStep,
  prevStep,
}) {
  const [authToken] = useLocalStorage("authToken", null);
  const [overstockExceptions, setOverstockExceptions] = useState(
    initialState.overstockExceptions || [],
  );
  const [search, setSearch] = useState("");
  const [models, setModels] = useState([]);
  const [brands, setBrands] = useState([]);

  const { loading } = useQuery(GET_ALL_MODELS, {
    context: { headers: { Authorization: ["Bearer", authToken].join(" ") } },
    onCompleted({ allModels }) {
      const map = {};
      for (const model of allModels) {
        map[model.brandName] = [...(map[model.brandName] || []), model];
      }

      const sorted = Object.keys(map)
        .sort((a, b) => a.localeCompare(b))
        .map((brandName) => ({
          brandName,
          models: map[brandName].sort((a, b) =>
            a.modelName.localeCompare(b.modelName),
          ),
        }));

      let sortedBrands = [];
      let sortedModels = [];
      for (const brand of sorted) {
        sortedBrands = [
          ...sortedBrands,
          { brandName: brand.brandName, modelsCount: brand.models.length },
        ];

        sortedModels = [
          ...sortedModels,
          ...brand.models.map((model) => ({
            ...model,
            searchTerms: [brand.brandName, model.modelName]
              .join(" ")
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase(),
          })),
        ];
      }

      setBrands(sortedBrands);
      setModels(sortedModels);
    },
  });

  const filteredModels = useMemo(() => {
    if (!search || !models) return [];

    const normalizedSearchTerm = search
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

    return models.filter((model) =>
      model.searchTerms.includes(normalizedSearchTerm),
    );
  }, [models, search]);

  console.log({ filteredModels, search });

  return (
    <GettingStartedLayout
      image="/market-intelligence.webp"
      noImageTransformations
      flexStart
      noBackgroundLogo
      fullWidthImage
      nextStep={() => nextStep({ overstockExceptions })}
      prevStep={() => prevStep({ overstockExceptions })}
    >
      <Box>
        <Typography variant="h5">
          Excepciones de inventario excedente
        </Typography>
        <Typography>
          ¿Quieres hacer excepciones en modelos específicos? Mostraremos tus
          ofertas a vehículos de los siguientes modelos aunque hayas alcanzado
          tus objetivos de inventario ideal:
        </Typography>
      </Box>
      <Box>
        <TextField
          variant="outlined"
          fullWidth
          autoFocus
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          placeholder="Buscar marcas o modelos"
        />
      </Box>
      <Box>
        {loading && <CircularProgress size={64} />}

        <List>
          {!loading && (
            <GroupedVirtuoso
              groupCounts={
                search
                  ? [filteredModels.length]
                  : brands.map((brand) => brand.modelsCount)
              }
              style={{ height: 480 }}
              groupContent={(idx) => (
                <ListSubheader>
                  {search ? "Resultados de búsqueda" : brands[idx].brandName}
                </ListSubheader>
              )}
              itemContent={(idx) => {
                const { modelId, modelName, brandName } = search
                  ? filteredModels[idx]
                  : models[idx];
                const selected = overstockExceptions.includes(modelId);
                return (
                  <>
                    <ListItem disablePadding>
                      <ListItemButton
                        dense
                        onClick={() =>
                          setOverstockExceptions(
                            selected
                              ? overstockExceptions.filter(
                                  (item) => modelId !== item,
                                )
                              : [...overstockExceptions, modelId],
                          )
                        }
                      >
                        <Checkbox
                          edge="start"
                          checked={selected}
                          tabIndex={-1}
                          disableRipple
                        />
                        <ListItemText
                          primary={modelName}
                          secondary={search ? brandName : null}
                        />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </>
                );
              }}
            />
          )}
        </List>
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedOverstockExceptions;
