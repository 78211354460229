import { Box, ButtonBase, Grow } from "@mui/material";
import { useState } from "react";

const DefaultWrap = ({ children }) => <>{children}</>;

function PictureButton({
  src,
  selected,
  onClick,
  disabled = false,
  variant = "storefront",
  Wrap = DefaultWrap,
}) {
  const [loaded, setLoaded] = useState(false);
  const [expired, setExpired] = useState(false);

  if (expired) return <></>;

  return (
    <Wrap>
      <Grow in={loaded}>
        <ButtonBase
          sx={(theme) => ({
            width: "100%",
            height: 200,
            overflow: "hidden",
            borderRadius: 1,
            ...(variant === "logo"
              ? {
                  width: 80,
                  height: 80,
                  mr: 1,
                  mb: 1,
                  border: selected
                    ? `3px solid ${theme.palette.secondary.main}`
                    : "1px solid #ccc",
                }
              : {}),
            ...(variant === "profile"
              ? {
                  width: 48,
                  height: 48,
                  mr: 2,
                  borderRadius: "100%",
                  border: selected
                    ? `3px solid ${theme.palette.secondary.main}`
                    : "1px solid #ccc",
                }
              : {}),
          })}
          onClick={onClick}
          onLoad={() => setLoaded(true)}
          disabled={disabled}
        >
          <Box
            component="img"
            src={src}
            alt=""
            sx={{
              width: "100%",
              height: 200,
              objectFit: "cover",
              ...(!selected
                ? {
                    opacity: 0.9,
                    transition: "all 200ms",
                    "&:not(:hover)": {
                      filter: "grayscale(1)",
                      opacity: 0.5,
                    },
                  }
                : {}),
              ...(variant === "logo"
                ? {
                    width: 80,
                    height: 80,
                  }
                : {}),
              ...(variant === "profile"
                ? {
                    width: 48,
                    height: 48,
                  }
                : {}),
            }}
            onLoad={(e) => {
              if (e.target.naturalWidth === 100 && e.target.naturalHeight) {
                setExpired(true);
              }
            }}
          />
        </ButtonBase>
      </Grow>
    </Wrap>
  );
}

export default PictureButton;
