import GettingStartedLocationsSingle from "./GettingStartedLocationsSingle";
import GettingStartedLocationsMultiple from "./GettingStartedLocationsMultiple";

function GettingStartedLocations({
  initialState,
  nextStep,
  prevStep,
  saveState,
  lastStep,
  ownProfile,
}) {
  return initialState.businessType === "business-unit" ? (
    <GettingStartedLocationsSingle
      initialState={initialState}
      nextStep={nextStep}
      prevStep={prevStep}
      saveState={saveState}
      lastStep={lastStep}
      ownProfile={ownProfile}
    />
  ) : (
    <GettingStartedLocationsMultiple
      initialState={initialState}
      nextStep={nextStep}
      prevStep={prevStep}
      saveState={saveState}
      lastStep={lastStep}
      ownProfile={ownProfile}
    />
  );
}

export default GettingStartedLocations;
