import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  Assistant,
  Check,
  InfoOutlined,
  Language,
  Web,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import useLocalStorage from "../hooks/useLocalStorage";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { isURL } from "validator";
import { useTheme } from "@emotion/react";

const GET_INVENTORY_PLATFORMS = gql`
  query GetInventoryPlatforms {
    me {
      company {
        liteType
      }
    }
  }
`;

const GET_ML_SELLER_BY_ITEM_URL = gql`
  query GetMlSellerByItemUrl($itemUrl: String!) {
    mlSellerByItemUrl(itemUrl: $itemUrl) {
      id
      nickname
      permalink
      user_type
    }
  }
`;

function BusinessUnitInventory({
  inventoryUrl,
  mlSeller,
  googlePlace,
  onChange,
  variant,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [authToken] = useLocalStorage("authToken", null);
  const [mlItemUrl, setMlItemUrl] = useState("");
  const [liteType, setLiteType] = useState("ventau");
  const { enqueueSnackbar } = useSnackbar();

  const { loading } = useQuery(GET_INVENTORY_PLATFORMS, {
    context: { headers: { Authorization: ["Bearer", authToken].join(" ") } },
    onCompleted({ me }) {
      setLiteType(me.company.liteType);
    },
  });

  const [getMlSellerByItemUrl, { loading: loadingMlSeller }] = useLazyQuery(
    GET_ML_SELLER_BY_ITEM_URL,
    {
      context: { headers: { Authorization: ["Bearer", authToken].join(" ") } },
      onCompleted(data) {
        if (data.mlSellerByItemUrl) {
          onChange({ mlSeller: data.mlSellerByItemUrl });
        }
      },
      onError(error) {
        enqueueSnackbar(error.message, { variant: "error" });
      },
    },
  );

  const validInventoryUrl = inventoryUrl && isURL(inventoryUrl.trim());

  return (
    <Box>
      <Typography sx={{ mb: 3 }}>
        Por último, queremos conocer tu inventario, de esta manera podemos
        mejorar nuestro entendimiento de tu situación actual y buscar las
        oportunidades correctas para tu negocio.
      </Typography>
      {loading && <CircularProgress size={48} />}
      {!loading && (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            lg={variant === "dialog" ? 6 : 4}
            sx={{ display: "flex" }}
          >
            <Card variant="outlined" sx={{ flex: 1 }}>
              <CardHeader
                title="Intelimotor"
                subheader={liteType !== "ventau" ? "Conectado" : "No conectado"}
                avatar={
                  <Badge
                    variant="dot"
                    color={liteType !== "ventau" ? "secondary" : "error"}
                  >
                    <Avatar src="intelimotor.jpg" />
                  </Badge>
                }
              />
              <Divider />
              <CardContent>
                {liteType === "ventau" ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <InfoOutlined color="disabled" sx={{ mr: 1 }} />
                    <Typography variant="body2" color="textSecondary">
                      Parece que no tienes cuenta de Intelimotor, no te
                      preocupes, puedes continuar.
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Check color="success" sx={{ mr: 1 }} />
                    <Typography variant="body2">
                      Vinculaste tu cuenta.
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={variant === "dialog" ? 6 : 4}
            sx={{ display: "flex" }}
          >
            <Card variant="outlined" sx={{ flex: 1 }}>
              <CardHeader
                title="Mercado Libre"
                subheader={mlSeller ? "Conectado" : "No conectado"}
                avatar={
                  <Badge variant="dot" color={mlSeller ? "secondary" : "error"}>
                    <Avatar src="mercadolibre.ico" />
                  </Badge>
                }
              />
              <Divider />
              <CardContent>
                {mlSeller && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Check color="success" sx={{ mr: 1 }} />
                    <Box>
                      <Typography variant="body2">
                        Vinculaste la cuenta:&nbsp;
                        <Link href={mlSeller.permalink} target="__blank">
                          {mlSeller.nickname}
                        </Link>
                        .
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        ¿No es tu cuenta?&nbsp;
                        <Link
                          onClick={() => onChange({ mlSeller: null })}
                          sx={{ cursor: "pointer" }}
                        >
                          Cambiar cuenta
                        </Link>
                        .
                      </Typography>
                    </Box>
                  </Box>
                )}

                {!mlSeller && (
                  <Box>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Ingresa la URL de uno de tus anuncios.
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        autoFocus
                        value={mlItemUrl}
                        onChange={(e) => setMlItemUrl(e.target.value)}
                        InputProps={
                          isMobile
                            ? {}
                            : {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Language />
                                  </InputAdornment>
                                ),
                              }
                        }
                        sx={{ mr: 1 }}
                        disabled={loadingMlSeller}
                        onKeyDown={(e) =>
                          e.key === "Enter" && mlItemUrl && mlItemUrl.trim()
                            ? getMlSellerByItemUrl({
                                variables: {
                                  itemUrl: mlItemUrl.trim().toLowerCase(),
                                },
                              })
                            : null
                        }
                      />
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() =>
                          getMlSellerByItemUrl({
                            variables: {
                              itemUrl: mlItemUrl.trim().toLowerCase(),
                            },
                          })
                        }
                        disabled={
                          loadingMlSeller || !mlItemUrl || !mlItemUrl.trim()
                        }
                      >
                        {loadingMlSeller ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Conectar"
                        )}
                      </Button>
                    </Box>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={variant === "dialog" ? 12 : 4}
            sx={{ display: "flex" }}
          >
            <Card variant="outlined" sx={{ flex: 1 }}>
              <CardHeader
                title={mlSeller ? "Tu sitio web (opcional)" : "Tu sitio web"}
                subheader={validInventoryUrl ? "Conectado" : "No conectado"}
                avatar={
                  <Badge
                    variant="dot"
                    color={validInventoryUrl ? "secondary" : "error"}
                  >
                    <Avatar>
                      <Web />
                    </Avatar>
                  </Badge>
                }
              />
              <Divider />
              <CardContent>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Ingresa la URL de la sección de inventario en sitio web.
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  autoFocus={Boolean(mlSeller)}
                  value={inventoryUrl}
                  onChange={(e) =>
                    onChange({
                      inventoryUrl: e.target.value,
                    })
                  }
                  InputProps={
                    isMobile
                      ? {}
                      : {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Language />
                            </InputAdornment>
                          ),
                        }
                  }
                  sx={{ mb: 2 }}
                />
                {Boolean(googlePlace.website) && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: isMobile ? 1 : 2,
                      }}
                    >
                      <Assistant color="action" sx={{ mr: 1 }} />
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={{ wordBreak: "break-word" }}
                      >
                        <span>Encontramos esta URL por ti:&nbsp;</span>
                        <b>{googlePlace.website}</b>
                      </Typography>
                      {!isMobile && (
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{ ml: 1, whiteSpace: "nowrap" }}
                          onClick={() =>
                            onChange({ inventoryUrl: googlePlace.website })
                          }
                        >
                          Usar URL
                        </Button>
                      )}
                    </Box>
                    {isMobile && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mb: 2,
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{ ml: 1 }}
                          onClick={() =>
                            onChange({ inventoryUrl: googlePlace.website })
                          }
                        >
                          Usar URL
                        </Button>
                      </Box>
                    )}
                  </>
                )}
                <Typography variant="caption" color="textSecondary">
                  También puedes ingresar tu perfil de seminuevos.com,
                  autocosmos.com.mx o tu tienda de Facebook.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default BusinessUnitInventory;
