import { Close, HelpOutline } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { useState } from "react";

function BusinessIntelligenceDialog({ trigger }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      {trigger === "button" && (
        <IconButton
          sx={{ position: "relative", top: -2 }}
          onClick={() => setOpen(true)}
        >
          <HelpOutline />
        </IconButton>
      )}
      {trigger !== "button" && (
        <Link onClick={() => setOpen(true)} href="#">
          {trigger}
        </Link>
      )}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          ¿Qué son los porcentajes de descuento?
          <IconButton
            sx={{ position: "absolute", top: 4, right: 4 }}
            onClick={() => setOpen(false)}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>
            La Inteligencia Artificial de Ventau calcula el&nbsp;
            <b>valor promedio de mercado</b>&nbsp;de cualquier auto a partir de
            un análisis en tiempo real de las principales plataformas de compra
            venta de vehículos y otras fuentes relevantes; este valor te ayuda a
            definir el mejor precio al que podrías vender ese auto hoy. Además,
            para determinar tu oferta de compra es necesario que tú definas
            un&nbsp;
            <b>porcentaje de descuento</b>&nbsp;a aplicar sobre ese valor
            promedio de mercado.
            <br />
            <br />
            En un ejemplo sencillo, digamos que el valor promedio de mercado de
            un auto es de 100 mil pesos, y tu porcentaje de descuento es del
            12%, en tal caso, la oferta automática inicial por parte de tu
            negocio al vendedor particular se haría por 88 mil pesos. De esta
            manera, Ventau te ayuda a mejorar la rentabilidad de cada compra qué
            realizas, siempre partiendo del valor promedio de mercado y tu
            porcentaje de descuento.
            <br />
            <br />
            En el caso de autos de tus propias marcas, es decir, las marcas de
            fabricantes de autos que tu Concesionario representa, los
            porcentajes de descuento normalmente son menores. Esto se debe a que
            el precio al que puedes vender esos autos suele estar por encima de
            ese valor promedio de mercado (al rededor de un 3% o 4%,
            aproximadamente), lo que te permite ser mucho más competitivo y
            hacer mejores ofertas.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default BusinessIntelligenceDialog;
