import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import WhatsAppButton from "./WhatsAppButton";
import {
  CalendarMonth,
  DirectionsCarOutlined,
  EditOutlined,
  Feedback,
  MailOutline,
  PersonOutline,
  PhoneOutlined,
  Today,
} from "@mui/icons-material";
import toNumber from "../utils/toNumber";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";

function Offer() {
  const navigate = useNavigate();
  const { ventauQuoteId, businessUnitId, action = "view" } = useParams();
  const [offer, setOffer] = useState(null);
  const [saving, setSaving] = useState(false);
  const [newInspectionDate, setNewInspectionDate] = useState(null);

  useEffect(() => {
    fetch(
      `${
        window.location.href.includes("localhost")
          ? "http://localhost"
          : "https://app.intelimotor.com"
      }/api/ventau/offers/${ventauQuoteId}/${businessUnitId}`,
      { cache: "no-store" },
    )
      .then((res) => res.json())
      .then((res) => setOffer(res));
  }, [ventauQuoteId, businessUnitId]);

  function handleAccept() {
    setSaving(true);
    fetch(
      `${
        window.location.href.includes("localhost")
          ? "http://localhost"
          : "https://app.intelimotor.com"
      }/api/ventau/offers/${ventauQuoteId}/${businessUnitId}/buyer-accept`,
      {
        cache: "no-store",
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      },
    ).then(
      () => {
        setOffer({ ...offer, status: "confirmed" });
        setSaving(false);
      },
      () => {},
    );
  }

  function handleCancel() {
    setSaving(true);
    fetch(
      `${
        window.location.href.includes("localhost")
          ? "http://localhost"
          : "https://app.intelimotor.com"
      }/api/ventau/offers/${ventauQuoteId}/${businessUnitId}/buyer-cancel`,
      {
        cache: "no-store",
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      },
    ).then(
      () => {
        navigate(`/offer/${ventauQuoteId}/${businessUnitId}/view`);
        setOffer({ ...offer, status: "canceled-by-buyer", isSelected: false });
        setSaving(false);
      },
      () => {},
    );
  }

  function handleChange() {
    setSaving(true);
    fetch(
      `${
        window.location.href.includes("localhost")
          ? "http://localhost"
          : "https://app.intelimotor.com"
      }/api/ventau/offers/${ventauQuoteId}/${businessUnitId}/buyer-change`,
      {
        cache: "no-store",
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          timestamp: newInspectionDate,
        }),
      },
    ).then(
      () => {
        navigate(`/offer/${ventauQuoteId}/${businessUnitId}/view`);
        setOffer({ ...offer, status: "requested-by-buyer" });
        setSaving(false);
      },
      () => {},
    );
  }

  const inThePast = Boolean(
    offer &&
      offer.isSelected &&
      offer.inspectionAt &&
      moment(offer.inspectionAt).isBefore(moment()),
  );

  return (
    <Fade in style={{ transitionDelay: "200ms" }}>
      <Container
        maxWidth="xl"
        sx={{
          py: 4,
        }}
      >
        <Stack>
          <Box textAlign="center">
            <Box component="img" src="/logo.webp" alt="Ventau" maxWidth={300} />
          </Box>

          {!offer && (
            <Box
              sx={{
                minHeight: 360,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={100} />
            </Box>
          )}

          {offer && !offer.isSelected && (
            <Typography variant="h4" sx={{ textAlign: "center", mt: 8 }}>
              Cita cancelada
            </Typography>
          )}

          {offer && offer.isSelected && (
            <Box>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={4}>
                  <Stack spacing={4}>
                    <Card variant="outlined">
                      <CardHeader
                        avatar={
                          <Avatar
                            src={offer.businessUnit.logo}
                            variant="rounded"
                          />
                        }
                        title={offer.businessUnit.name}
                        subheader="Concesionario"
                      />
                      <CardMedia
                        component="img"
                        height="194"
                        image={offer.businessUnit.storefront}
                        alt={offer.businessUnit.name}
                      />
                    </Card>
                    <Card variant="outlined">
                      <CardHeader
                        avatar={
                          <Avatar variant="rounded" className="gradient">
                            {offer.seller.name[0]}
                          </Avatar>
                        }
                        title={offer.seller.name}
                        subheader="Vendedor particular"
                      />
                      <Divider />
                      <List disablePadding>
                        <ListItem>
                          <ListItemIcon>
                            <PersonOutline />
                          </ListItemIcon>
                          <ListItemText
                            primary={offer.seller.name}
                            secondary="Nombre"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <MailOutline />
                          </ListItemIcon>
                          <ListItemText
                            primary={offer.seller.email}
                            secondary="Correo electrónico"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <PhoneOutlined />
                          </ListItemIcon>
                          <ListItemText
                            primary={offer.seller.phone}
                            secondary="Teléfono"
                          />
                        </ListItem>
                      </List>
                    </Card>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Card variant="outlined">
                    <CardHeader
                      avatar={
                        <Avatar
                          variant="rounded"
                          src={
                            offer.seller.brandLogo
                              ? offer.seller.brandLogo.value
                              : null
                          }
                        >
                          <DirectionsCarOutlined />
                        </Avatar>
                      }
                      title="Vehículo"
                    />
                    <Divider />
                    <List disablePadding>
                      <ListItem>
                        <ListItemText
                          primary={offer.seller.brand}
                          secondary="Marca"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={offer.seller.model}
                          secondary="Modelo"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={offer.seller.year}
                          secondary="Año"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={offer.seller.trim}
                          secondary="Versión"
                        />
                      </ListItem>
                      {offer.type !== "consignment" && (
                        <ListItem>
                          <ListItemText
                            primary={`$${toNumber(Math.floor(offer.amount))}`}
                            secondary="Oferta preliminar"
                          />
                        </ListItem>
                      )}
                      <ListItem>
                        <ListItemText
                          primary={`${offer.seller.kms ? toNumber(offer.seller.kms) : "-"} km`}
                          secondary="Kilometraje"
                        />
                      </ListItem>
                    </List>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Card variant="outlined">
                    <CardHeader
                      avatar={
                        <Avatar
                          variant="rounded"
                          className={
                            offer.status === "confirmed" ? "gradient" : null
                          }
                        >
                          <Today />
                        </Avatar>
                      }
                      title="Cita de inspección"
                      subheader={
                        offer.status === "confirmed"
                          ? "Confirmada"
                          : "Por confirmar"
                      }
                    />
                    <Divider />
                    <List disablePadding>
                      <ListItem>
                        <ListItemIcon>
                          <CalendarMonth />
                        </ListItemIcon>
                        <ListItemText
                          primary={offer.inspectionDate}
                          secondary="Fecha"
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={() => {
                              setNewInspectionDate(null);
                              navigate(
                                `/offer/${ventauQuoteId}/${businessUnitId}/edit`,
                              );
                            }}
                            disabled={inThePast}
                          >
                            <EditOutlined />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <MailOutline />
                        </ListItemIcon>
                        <ListItemText
                          primary={offer.inspectionTimeSlot}
                          secondary="Hora"
                        />
                        <IconButton
                          onClick={() => {
                            setNewInspectionDate(null);
                            navigate(
                              `/offer/${ventauQuoteId}/${businessUnitId}/edit`,
                            );
                          }}
                          disabled={inThePast}
                        >
                          <EditOutlined />
                        </IconButton>
                      </ListItem>
                    </List>

                    <CardActions sx={{ justifyContent: "flex-end" }}>
                      <Button
                        color="error"
                        variant="outlined"
                        size="large"
                        onClick={() =>
                          navigate(
                            `/offer/${ventauQuoteId}/${businessUnitId}/cancel`,
                          )
                        }
                        disabled={saving || inThePast}
                      >
                        Cancelar cita
                      </Button>

                      {offer.status !== "confirmed" && (
                        <Button
                          variant="contained"
                          size="large"
                          onClick={handleAccept}
                          {...(saving
                            ? { disabled: true }
                            : { className: "gradient" })}
                        >
                          {saving && (
                            <CircularProgress size={24} sx={{ mr: 1 }} />
                          )}
                          Aceptar cita
                        </Button>
                      )}
                    </CardActions>
                  </Card>

                  <Box sx={{ pb: 2 }}></Box>

                  {inThePast && (
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar variant="rounded" className="gradient">
                            <Feedback />
                          </Avatar>
                        }
                        title={`¿Cómo te fue con ${offer.seller.name}?`}
                        subheader="Completa el estatus de tu prospecto"
                      />
                      <CardContent>
                        <Typography>
                          {`Queremos saber cómo te fue en la cita de inspección con ${offer.seller.name}`}
                        </Typography>
                      </CardContent>

                      <CardActions sx={{ justifyContent: "flex-end" }}>
                        <Button
                          variant="outlined"
                          onClick={() =>
                            (window.location.href = offer.buyerLostFeedbackUrl)
                          }
                        >
                          {offer.type === "purchase" && "No lo compré"}
                          {offer.type === "consignment" &&
                            "No lo tomé a consignación"}
                        </Button>
                        {offer.type === "purchase" && (
                          <Button
                            variant="outlined"
                            onClick={() =>
                              (window.location.href =
                                offer.buyerNegotiationFeedbackUrl)
                            }
                          >
                            En negociación
                          </Button>
                        )}
                        <Button
                          className="gradient"
                          variant="contained"
                          onClick={() =>
                            (window.location.href = offer.buyerWonFeedbackUrl)
                          }
                        >
                          {offer.type === "purchase" && "¡Lo compré!"}
                          {offer.type === "consignment" && "¡En consignación!"}
                        </Button>
                      </CardActions>
                    </Card>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
        </Stack>
        <WhatsAppButton />

        <Dialog
          open={Boolean(offer) && action === "cancel" && !inThePast}
          onClose={() =>
            navigate(`/offer/${ventauQuoteId}/${businessUnitId}/view`)
          }
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            ¿Estás seguro de que quieres cancelar esta cita de inspección?
          </DialogTitle>
          <DialogContent>
            Nosotros nos encargaremos de comunicarle al vendedor particular que
            cancelaste su cita.
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              onClick={() =>
                navigate(`/offer/${ventauQuoteId}/${businessUnitId}/view`)
              }
              disabled={saving}
            >
              Cerrar
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleCancel}
              disabled={saving}
            >
              {saving && <CircularProgress size={24} sx={{ mr: 1 }} />}
              Cancelar cita
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={Boolean(offer) && action === "edit" && !inThePast}
          onClose={() =>
            navigate(`/offer/${ventauQuoteId}/${businessUnitId}/view`)
          }
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Proponer nueva cita de inspección</DialogTitle>
          <DialogContent>
            <Typography>
              Una vez que propongas la nueva cita de inspección nosotros la
              comunicaremos al vendedor particular. Te notificaremos una vez que
              que el vendedor particular haya aceptado o declinado tu propuesta.
            </Typography>

            <Box sx={{ pt: 4 }}>
              <DateTimePicker
                label="Nueva cita de inspección"
                minDate={moment().add(2, "day")}
                maxDate={moment().add(1, "month").endOf("month")}
                value={newInspectionDate && moment(newInspectionDate)}
                onChange={(newDate) => {
                  setNewInspectionDate(newDate.toDate());
                }}
                showDaysOutsideCurrentMonth
                disableHighlightToday
                ampm
                sx={{ width: "100%" }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              onClick={() =>
                navigate(`/offer/${ventauQuoteId}/${businessUnitId}/view`)
              }
              disabled={saving}
            >
              Cerrar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleChange}
              disabled={saving || !newInspectionDate}
            >
              {saving && <CircularProgress size={24} sx={{ mr: 1 }} />}
              Proponer cita
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Fade>
  );
}

export default Offer;
