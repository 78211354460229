import { Box, Grid, Typography } from "@mui/material";

import PictureButton from "./PictureButton";
import CustomPicture from "./CustomPicture";

const Wrap = ({ children }) => (
  <Grid item xs={6} sm={4} md={3} xl={2}>
    {children}
  </Grid>
);

const DialogWrap = ({ children }) => (
  <Grid item xs={6} sm={4} md={4} xl={4}>
    {children}
  </Grid>
);

function BusinessUnitStorefront({
  storefront,
  googlePlace,
  onChange,
  variant,
}) {
  return (
    <Box>
      <Typography sx={{ mb: 1 }}>
        Ayúdanos a seleccionar la mejor fotografía de la fachada de tu
        Concesinario, la verán los vendedores particulares a la hora de que
        agenden su cita de inspección con tu negocio.
      </Typography>
      <Grid container spacing={1}>
        <Grid
          item
          xs={6}
          sm={4}
          md={variant === "dialog" ? 4 : 3}
          xl={variant === "dialog" ? 4 : 2}
        >
          <CustomPicture
            selectedUrl={storefront}
            onChange={(url) => onChange(url)}
          />
        </Grid>

        {(googlePlace.photos || []).map((photo) => (
          <PictureButton
            src={photo.url}
            selected={photo.url === storefront}
            onClick={() => onChange(photo.url)}
            Wrap={variant === "dialog" ? DialogWrap : Wrap}
          />
        ))}
      </Grid>
    </Box>
  );
}

export default BusinessUnitStorefront;
