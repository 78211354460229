import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { CssBaseline } from "@mui/material";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { LocalizationProvider, esES } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { SnackbarProvider } from "notistack";
import ReactGA from "react-ga4";
import "moment/locale/es";
import { pdfjs } from "react-pdf";

import Router from "./components/Router";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./App.css";

ReactGA.initialize([{ trackingId: "G-Z6D7DHVVYT" }]);

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const client = new ApolloClient({
  uri: window.location.href.includes("localhost")
    ? "http://localhost/graphql"
    : "https://app.intelimotor.com/graphql",
  cache: new InMemoryCache(),
});

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "rgb(30, 187, 240)",
      contrastText: "#fff",
    },
    secondary: {
      main: "rgb(0, 220, 168)",
      contrastText: "#fff",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        sizeLarge: {
          minHeight: 50,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: { fontWeight: "bold" },
        h2: { fontWeight: "bold" },
        h3: { fontWeight: "bold" },
        h4: { fontWeight: "bold" },
        h5: { fontWeight: "bold" },
        h6: { fontWeight: "bold" },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeMedium: {
          height: 40,
          borderRadius: 20,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: "none",
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          marginTop: "0 !important",
          marginBottom: "0 !important",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar: {
          background:
            "linear-gradient(135deg, rgb(30, 187, 240) 30%, rgb(57, 223, 170) 100%)",
        },
      },
    },
  },
});

function App() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="es"
            localeText={
              esES.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <BrowserRouter>
              <>
                <CssBaseline />
                <Router />
              </>
            </BrowserRouter>
          </LocalizationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
