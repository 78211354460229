import { Add, DeleteOutline, EditOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Fragment, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import isEmail from "validator/lib/isEmail";
import CustomPicture from "./CustomPicture";

function BusinessUnitUsers({ users, defaultUsers, onChange }) {
  const { enqueueSnackbar } = useSnackbar();
  const [openUser, setOpenUser] = useState(null);
  const [deletingState, setDeletingState] = useState(null);

  useEffect(() => {
    if (users.length === 0) {
      onChange({ users: defaultUsers });
    }
  }, [users, defaultUsers, onChange]);

  function handleSave() {
    if (!isEmail(openUser.email.trim())) {
      return enqueueSnackbar(
        "Ingresa una dirección de correo electrónico de trabajo válida.",
        { variant: "error" },
      );
    }

    const isNew = !users.some((user) => user.id === openUser.id);

    const normalizedEmail = openUser.email.trim().toLowerCase();

    if (isNew && users.some((user) => user.email === normalizedEmail)) {
      return enqueueSnackbar(
        "Ya agregaste a este usuario en este Concesionario.",
        { variant: "error" },
      );
    }

    onChange({
      users: !isNew
        ? users.map((user) =>
            user.id === openUser.id
              ? { ...user, ...openUser, email: normalizedEmail }
              : user,
          )
        : [...users, { ...openUser, email: normalizedEmail }],
    });

    setOpenUser(null);
  }

  const sections = [
    {
      title: "Asesores de servicio",
      description:
        "Se encargan de llevar la comunicación con los vendedores particulares y de gestionar las citas de inspección.",
      role: "crm",
      addButton: "Agregar un asesor de servicio",
      deleteButton: "Eliminar como asesor de servicio",
      roleName: "Asesor de servicio",
    },
    {
      title: "Valuadores",
      description:
        "Se encargan de efectuar las inspecciones de los vehículos, la disponibilidad de tu negocio para agendar inspecciones se hace basada en la disponibilidad de tus valuadores.",
      role: "inspection",
      addButton: "Agregar un valuador",
      deleteButton: "Eliminar como valuador",
      roleName: "Valuador",
    },
    {
      title: "Administradores",
      description:
        "Tienen acceso a la configuración del perfil del Concesionario.",
      role: "settings",
      addButton: "Agregar un administrador",
      deleteButton: "Eliminar como administrador",
      roleName: "Administrador",
    },
  ];

  return (
    <>
      {sections.map((section) => {
        const sectionUsers = users.filter((user) =>
          (user.roles || []).includes(section.role),
        );

        return (
          <Box key={section.role}>
            <Typography sx={{ mt: 2 }} variant="subtitle2">
              {section.title}
            </Typography>
            <Typography sx={{ mb: 2 }} variant="body2">
              {section.description}
            </Typography>
            <Paper sx={{ maxWidth: 480 }} variant="outlined">
              <List>
                {sectionUsers.map((user) => (
                  <Fragment key={user.id}>
                    <ListItem>
                      <ListItemAvatar>
                        <CustomPicture
                          selectedUrl={user.picture}
                          onChange={(newPicture) =>
                            onChange({
                              users: users.map((item) =>
                                item.id === user.id
                                  ? { ...item, picture: newPicture }
                                  : item,
                              ),
                            })
                          }
                          variant="profile"
                          reactiveUrl
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={user.name}
                        secondary={user.email}
                      />
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => setOpenUser({ ...user })}>
                          <EditOutlined />
                        </IconButton>
                        <IconButton
                          onClick={(e) => {
                            if (user.roles.length > 1) {
                              setDeletingState({
                                user,
                                section,
                                anchorEl: e.target,
                              });
                              return;
                            }

                            onChange({
                              users: users.filter(
                                (item) => item.id !== user.id,
                              ),
                            });
                          }}
                          disabled={section.role === "settings" && user.locked}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() =>
                      setOpenUser({
                        id: uuid(),
                        email: "",
                        name: "",
                        picture: null,
                        roles: [section.role],
                      })
                    }
                  >
                    <ListItemIcon>
                      <Add />
                    </ListItemIcon>
                    <ListItemText secondary={section.addButton} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Paper>
          </Box>
        );
      })}

      {openUser && (
        <Dialog open onClose={() => setOpenUser(null)}>
          <DialogContent>
            <TextField
              variant="outlined"
              label="Nombre y apellido"
              fullWidth
              autoFocus
              type="text"
              value={openUser.name}
              onChange={(e) =>
                setOpenUser({ ...openUser, name: e.target.value })
              }
              margin="dense"
              disabled={openUser.locked}
            />
            <TextField
              variant="outlined"
              label="Dirección de correo electrónico de trabajo"
              fullWidth
              type="email"
              value={openUser.email}
              onChange={(e) =>
                setOpenUser({ ...openUser, email: e.target.value })
              }
              helperText="Recibirá un correo de invitación en esta dirección."
              margin="dense"
              disabled={openUser.locked}
            />
            <FormControl component="fieldset" sx={{ mt: 4 }}>
              <FormLabel component="legend">Responsabilidades</FormLabel>
              <FormGroup>
                {sections.map((section) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={section.role}
                        checked={openUser.roles.includes(section.role)}
                        onChange={(e) =>
                          setOpenUser({
                            ...openUser,
                            roles: e.target.checked
                              ? [...openUser.roles, section.role]
                              : openUser.roles.filter(
                                  (role) => role !== section.role,
                                ),
                          })
                        }
                        disabled={
                          openUser.locked && section.role === "settings"
                        }
                      />
                    }
                    label={section.roleName}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => setOpenUser()}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={
                !openUser.name ||
                !openUser.name.trim() ||
                !openUser.email ||
                !openUser.email.trim() ||
                openUser.roles.length === 0
              }
            >
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {deletingState && (
        <Menu
          open
          anchorEl={deletingState.anchorEl}
          onClose={() => setDeletingState(null)}
        >
          <MenuItem
            onClick={() => {
              setDeletingState(null);
              onChange({
                users: users.map((user) =>
                  user.id === deletingState.user.id
                    ? {
                        ...user,
                        roles: user.roles.filter(
                          (role) => role !== deletingState.section.role,
                        ),
                      }
                    : user,
                ),
              });
            }}
          >
            {deletingState.section.deleteButton}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setDeletingState(null);
              onChange({
                users: users.filter(
                  (item) => item.id !== deletingState.user.id,
                ),
              });
            }}
            disabled={deletingState.user.locked}
          >
            Eliminar de todos los roles
          </MenuItem>
        </Menu>
      )}
    </>
  );
}

export default BusinessUnitUsers;
