import { Box, Typography } from "@mui/material";
import GettingStartedLayout from "./GettingStartedLayout";

function GettingStartedWelcome({ nextStep }) {
  return (
    <GettingStartedLayout image="/sedan-blanco.png" nextStep={nextStep}>
      <Box>
        <Typography variant="h5" sx={{ mb: 3 }}>
          Perfecto, ahora configuremos tu cuenta.
        </Typography>
        <Typography>
          Te tomará solo unos minutos, a partir de tus respuestas conoceremos tu
          manera de comprar para que Ventau pueda encontrar justo lo que estás
          buscando.
        </Typography>
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedWelcome;
