import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";
import moment from "moment";

function GettingStartedConsignmentsMinYear({
  initialState,
  nextStep,
  prevStep,
}) {
  const [consignmentsNoMinYear, setConsignmentsNoMinYear] = useState(
    typeof initialState.consignmentsNoMinYear !== "undefined" &&
      initialState.consignmentsNoMinYear !== null
      ? initialState.consignmentsNoMinYear
      : null,
  );

  const [consignmentsMinYear, setConsignmentsMinYear] = useState(
    typeof initialState.consignmentsMinYear !== "undefined" &&
      initialState.consignmentsMinYear !== null
      ? initialState.consignmentsMinYear
      : null,
  );

  return (
    <GettingStartedLayout
      image="/sedan-negro.png"
      nextStep={() => nextStep({ consignmentsNoMinYear, consignmentsMinYear })}
      prevStep={() => prevStep({ consignmentsNoMinYear, consignmentsMinYear })}
      disabled={
        consignmentsNoMinYear === null ||
        (!consignmentsNoMinYear && !consignmentsMinYear)
      }
    >
      <Box>
        <Typography variant="h5">
          ¿Tienes algún limitante para el año en autos que aceptas para
          consignación?
        </Typography>
        <Typography>Selecciona una opción.</Typography>
      </Box>
      <Box>
        {[
          { type: false, label: "Si" },
          { type: true, label: "No" },
        ].map(({ type, label }) => (
          <Chip
            key={label}
            label={label}
            sx={{ mr: 1, mb: 1, minWidth: 80 }}
            variant="outlined"
            onClick={() => setConsignmentsNoMinYear(type)}
            {...(consignmentsNoMinYear === type
              ? {
                  variant: "filled",
                  color: "primary",
                  className: "gradient",
                }
              : {})}
          />
        ))}
      </Box>

      {consignmentsNoMinYear === false && (
        <Box>
          <Typography sx={{ mb: 2 }}>
            ¿A partir de qué año te interesa aceptar autos para consignación?
          </Typography>

          <FormControl sx={{ minWidth: 140 }}>
            <InputLabel id="demo-simple-select-label">Año</InputLabel>
            <Select
              value={consignmentsMinYear}
              onChange={(e) => setConsignmentsMinYear(e.target.value)}
              labelId="demo-simple-select-label"
              label="Año"
            >
              {[...new Array(12)]
                .map((item, idx) => String(moment().year() + 1 - idx))
                .map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </GettingStartedLayout>
  );
}

export default GettingStartedConsignmentsMinYear;
