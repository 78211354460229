import { Box, Chip, Typography } from "@mui/material";
import { useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";

function GettingStartedConsignmentsRequirements({
  initialState,
  nextStep,
  prevStep,
}) {
  const [requiresPhysicalConsignments, setRequiresPhysicalConsignments] =
    useState(
      typeof initialState.requiresPhysicalConsignments !== "undefined" &&
        initialState.requiresPhysicalConsignments !== null
        ? initialState.requiresPhysicalConsignments
        : null,
    );

  return (
    <GettingStartedLayout
      image="/sedan-negro.png"
      nextStep={() => nextStep({ requiresPhysicalConsignments })}
      prevStep={() => prevStep({ requiresPhysicalConsignments })}
      disabled={requiresPhysicalConsignments === null}
    >
      <Box>
        <Typography variant="h5">
          ¿Requieres que el auto a consignación se quede en tu agencia?
        </Typography>
        <Typography>Selecciona una opción.</Typography>
      </Box>
      <Box>
        {[
          { type: true, label: "Si" },
          { type: false, label: "No" },
        ].map(({ type, label }) => (
          <Chip
            key={label}
            label={label}
            sx={{ mr: 1, mb: 1, minWidth: 80 }}
            variant="outlined"
            onClick={() => setRequiresPhysicalConsignments(type)}
            {...(requiresPhysicalConsignments === type
              ? {
                  variant: "filled",
                  color: "primary",
                  className: "gradient",
                }
              : {})}
          />
        ))}
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedConsignmentsRequirements;
