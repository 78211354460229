import { Box, Chip, CircularProgress, Typography } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import useLocalStorage from "../hooks/useLocalStorage";
import { DateCalendar } from "@mui/x-date-pickers";
import moment from "moment";

const GET_AVAILABLE_TIME_SLOTS = gql`
  query GetAvailableTimeSlots($date: String!) {
    availableTimeSlots: ventauAvailableTimeSlots(date: $date)
  }
`;

function ScheduleVerification({ date, setDate, timeSlot, setTimeSlot }) {
  const [authToken] = useLocalStorage("authToken", null);

  const { loading, data } = useQuery(GET_AVAILABLE_TIME_SLOTS, {
    context: { headers: { Authorization: ["Bearer", authToken].join(" ") } },
    variables: { date },
  });

  return (
    <>
      <Box>
        <Typography variant="h5">Verifica tu negocio</Typography>
        <Typography>
          Agenda una llamada de pocos minutos con nuestro equipo para verificar,
          revisar y complementar tus especificaciones operativas y reglas
          establecidas.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
          Selecciona una fecha
        </Typography>
        <DateCalendar
          minDate={moment().add(1, "day")}
          maxDate={moment().add(1, "month").endOf("month")}
          value={moment(date)}
          onChange={(newDate) => {
            setDate(newDate.format("YYYY-MM-DD"));
            setTimeSlot(null);
          }}
          sx={{ mx: 0, border: "1px solid #ccc", borderRadius: 1 }}
          showDaysOutsideCurrentMonth
          shouldDisableDate={(date) => date.day() === 0 || date.day() === 6}
          disableHighlightToday
        />
      </Box>
      <Box>
        <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
          Selecciona un horario
        </Typography>
        {loading && <CircularProgress size={48} />}

        {!loading && ((data && data.availableTimeSlots) || []).length === 0 && (
          <Typography variant="caption" color="textSecondary">
            No hay horarios disponibles en la fecha seleccionada.
          </Typography>
        )}
        {!loading &&
          ((data && data.availableTimeSlots) || []).map((item) => (
            <Chip
              key={item}
              label={item}
              sx={{ mr: 1, mb: 1 }}
              variant="outlined"
              onClick={() => setTimeSlot(item)}
              {...(item === timeSlot
                ? {
                    variant: "filled",
                    color: "primary",
                    className: "gradient",
                  }
                : {})}
            />
          ))}
      </Box>
    </>
  );
}

export default ScheduleVerification;
