import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";

function RuleDialog({ rule, onChange, onClose }) {
  const [discount, setDiscount] = useState(rule.discount);
  const [noOffer, setNoOffer] = useState(rule.noOffer);
  const [saving, setSaving] = useState(false);

  const logoAttribute = useMemo(
    () => rule.brand.attributes.find((attribute) => attribute.name === "logo"),
    [rule?.brand?.id]
  );

  async function save() {
    setSaving(true);
    await onChange({ ...rule, discount, noOffer });
    setSaving(false);
  }

  return (
    <Dialog
      open={Boolean(rule)}
      onClose={!saving ? onClose : null}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Avatar
          variant="rounded"
          src={logoAttribute?.value}
          className={logoAttribute?.value ? "" : "gradient"}
          sx={{ mr: 1 }}
        >
          {rule.brand.name[0].toUpperCase()}
        </Avatar>
        <Typography variant="h6">{rule.brand.name}</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          {!noOffer && (
            <TextField
              label="Descuento sobre el valor de mercado"
              variant="outlined"
              value={discount}
              type="number"
              fullWidth
              onChange={(e) => setDiscount(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                componentsProps: {
                  input: {
                    min: 0,
                    max: 100,
                  },
                },
              }}
              autoFocus
              onKeyDown={(e) => {
                if (e.key === "Enter" && discount && discount > 0) save();
              }}
            />
          )}

          {/* no offer */}
          <FormControlLabel
            control={
              <Switch
                checked={noOffer}
                onChange={(e) => setNoOffer(e.target.checked)}
                color="error"
              />
            }
            label="No comprar autos de esta marca"
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose} disabled={saving}>
          Cancelar
        </Button>
        <Button
          size="large"
          variant="contained"
          {...((!noOffer && (!discount || discount <= 0)) || saving
            ? { disabled: true }
            : { className: "gradient" })}
          onClick={save}
        >
          {saving ? <CircularProgress size={24} /> : "Guardar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RuleDialog;
