import { useTheme } from "@emotion/react";
import { WhatsApp } from "@mui/icons-material";
import { Fab, Tooltip, useMediaQuery } from "@mui/material";

function WhatsAppButton() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Tooltip
      title="¿Necesitas ayuda?"
      placement="left"
      componentsProps={{
        tooltip: {
          style: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            border: "1px solid #ccc",
            fontSize: 14,
          },
        },
      }}
    >
      <Fab
        size={isMobile ? "medium" : "large"}
        color="success"
        sx={{
          backgroundColor: "#25d366",
          "&:hover": {
            backgroundColor: "#128c7e",
          },
          ...(isMobile
            ? { position: "fixed", right: 12, bottom: 8 }
            : {
                position: "fixed",
                right: 32,
                bottom: 24,
                width: 60,
                height: 60,
              }),
        }}
        href={`https://wa.me/5215570058768?text=${encodeURIComponent(
          "Hola, necesito ayuda en mi proceso de afiliación.",
        )}`}
        target="_blank"
      >
        <WhatsApp fontSize="large" />
      </Fab>
    </Tooltip>
  );
}

export default WhatsAppButton;
