import { gql, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Switch,
  TextField,
} from "@mui/material";
import { useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { Close, Search } from "@mui/icons-material";

const GET_MODELS = gql`
  query GetModels($type: String, $parents: [ID]) {
    models: entities(type: $type, parents: $parents) {
      id
      name
      attributes {
        name
        value
      }
    }
  }
`;

function SpecificRuleDialog({
  rule,
  specificRule,
  onChange,
  onClose,
  variant,
}) {
  const [authToken] = useLocalStorage("authToken", null);
  const [models, setModels] = useState(specificRule.models);
  const [discount, setDiscount] = useState(specificRule.discount);
  const [noOffer, setNoOffer] = useState(specificRule.noOffer);
  const [search, setSearch] = useState("");
  const [activeStep, setActiveStep] = useState(
    specificRule.models.length === 0 || variant === "consignments" ? 0 : 1,
  );
  const [saving, setSaving] = useState(false);

  const { data, loading } = useQuery(GET_MODELS, {
    context: { headers: { Authorization: ["Bearer", authToken].join(" ") } },
    variables: {
      type: "model",
      parents: [rule.brand.id],
    },
  });

  async function save() {
    setSaving(true);
    await onChange({ ...specificRule, discount, noOffer, models });
    setSaving(false);
  }

  return (
    <Dialog
      open={Boolean(specificRule)}
      onClose={!saving ? onClose : null}
      fullWidth
      maxWidth="md"
    >
      <DialogContent sx={{ position: "relative" }}>
        <IconButton
          sx={{ position: "absolute", top: 4, right: 4 }}
          onClick={onClose}
          disabled={saving}
        >
          <Close />
        </IconButton>
        <Stepper orientation="vertical" activeStep={activeStep}>
          {/* models */}
          <Step>
            <StepLabel>
              {variant === "consignments"
                ? "Selecciona los modelos para los que aplica la excepción"
                : "Selecciona los modelos para los que aplica tu oferta"}
            </StepLabel>
            <StepContent sx={{ pt: 3 }}>
              <Stack spacing={3}>
                {/* search */}
                <Box>
                  <TextField
                    variant="outlined"
                    fullWidth
                    autoFocus
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Buscar modelos"
                  />
                </Box>

                {/* models */}
                <Box>
                  {loading && <CircularProgress size={64} />}
                  {data &&
                    data.models &&
                    data.models
                      .filter(
                        (model) =>
                          !search ||
                          !search.trim() ||
                          model.name
                            .toLowerCase()
                            .includes(search.trim().toLowerCase()),
                      )
                      .map((model) => (
                        <Chip
                          key={model.id}
                          label={model.name}
                          sx={{ mr: 1, mb: 1 }}
                          variant="outlined"
                          onClick={() =>
                            setModels(
                              models.some((item) => item.id === model.id)
                                ? models.filter((item) => item.id !== model.id)
                                : [...models, model],
                            )
                          }
                          {...(models.some((item) => item.id === model.id)
                            ? {
                                variant: "filled",
                                color: "primary",
                                className: "gradient",
                              }
                            : {})}
                        />
                      ))}
                </Box>

                {/* actions */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    size="large"
                    variant="contained"
                    {...(models.length === 0
                      ? {
                          disabled: true,
                        }
                      : { className: "gradient" })}
                    onClick={() => setActiveStep(1)}
                  >
                    Continuar
                  </Button>
                </Box>
              </Stack>
            </StepContent>
          </Step>

          {/* discount and noOffer */}
          <Step>
            <StepLabel>
              {variant === "consignments"
                ? "Configura la excepción"
                : "Indica el porcentaje de descuento"}
            </StepLabel>
            <StepContent sx={{ pt: 3 }}>
              <Stack spacing={3}>
                {/* discount */}
                {!noOffer && variant !== "consignments" && (
                  <TextField
                    label="Descuento sobre el valor de mercado"
                    variant="outlined"
                    value={discount}
                    type="number"
                    fullWidth
                    onChange={(e) => setDiscount(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      componentsProps: {
                        input: {
                          min: 0,
                          max: 100,
                        },
                      },
                    }}
                    autoFocus
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && discount && discount > 0) save();
                    }}
                    placeholder="Ej. 14"
                  />
                )}

                {/* no offer */}
                {variant === "consignments" ? (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!noOffer}
                        onChange={(e) => setNoOffer(!e.target.checked)}
                      />
                    }
                    label={
                      noOffer
                        ? "No aceptar autos de estos modelos para consignación"
                        : "Si aceptar autos de estos modelos para consignación"
                    }
                  />
                ) : (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={noOffer}
                        onChange={(e) => setNoOffer(e.target.checked)}
                        color="error"
                      />
                    }
                    label="No comprar autos de estos modelos"
                  />
                )}

                {/* actions */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    color="inherit"
                    onClick={() => setActiveStep(0)}
                    disabled={saving}
                  >
                    Regresar
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    {...((variant !== "consignments" &&
                      !noOffer &&
                      (!discount || discount <= 0)) ||
                    models.length === 0 ||
                    saving
                      ? {
                          disabled: true,
                        }
                      : { className: "gradient" })}
                    onClick={save}
                  >
                    {saving ? <CircularProgress size={24} /> : "Guardar"}
                  </Button>
                </Box>
              </Stack>
            </StepContent>
          </Step>
        </Stepper>
      </DialogContent>
    </Dialog>
  );
}

export default SpecificRuleDialog;
