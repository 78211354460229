import { gql, useQuery } from "@apollo/client";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  Link,
  ListSubheader,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Search } from "@mui/icons-material";
import { v4 as uuid } from "uuid";
import { GroupedVirtuoso } from "react-virtuoso";

import useLocalStorage from "../hooks/useLocalStorage";
import Rule from "./Rule";
import GettingStartedLayout from "./GettingStartedLayout";
import BusinessIntelligenceDialog from "./BusinessIntelligenceDialog";

const GET_BRANDS = gql`
  query GetBrands($type: String, $parents: [ID]) {
    brands: entities(type: $type, parents: $parents) {
      id
      name
      attributes {
        name
        value
      }
    }
  }
`;

function GettingStartedRules({ initialState, nextStep, prevStep, saveState }) {
  const [authToken] = useLocalStorage("authToken", null);
  const [ownBrandsRules, setOwnBrandsRules] = useState(
    initialState.ownBrandsRules || [],
  );
  const [otherBrandsRules, setOtherBrandsRules] = useState(
    initialState.otherBrandsRules || [],
  );
  const [search, setSearch] = useState("");
  const [mode, setMode] = useState(initialState.mode || "basic");
  const [saving, setSaving] = useState(null);

  const { loading } = useQuery(GET_BRANDS, {
    context: { headers: { Authorization: ["Bearer", authToken].join(" ") } },
    variables: {
      type: "brand",
      parents: ["5cdc482fe9b82b87f832bcf7"],
    },
    onCompleted({ brands }) {
      const ownBrands = initialState.businessUnits
        .filter((businessUnit) => businessUnit.googlePlace)
        .reduce(
          (arr, businessUnit) =>
            businessUnit.noOwnBrands
              ? arr
              : [...arr, ...businessUnit.ownBrands],
          [],
        );

      if (ownBrands.length !== ownBrandsRules.length) {
        setOwnBrandsRules(
          brands
            .filter((brand) =>
              ownBrands.some((ownBrand) => ownBrand.id === brand.id),
            )
            .map((brand) => ({
              id: uuid(),
              brand,
              noOffer: false,
              specificRules: [],
              discount: initialState.discountOwnBrands,
              appliesTo: initialState.businessUnits
                .filter((businessUnit) => businessUnit.googlePlace)
                .filter(
                  (businessUnit) =>
                    !businessUnit.noOwnBrands &&
                    businessUnit.ownBrands.some(
                      (ownBrand) => ownBrand.id === brand.id,
                    ),
                ),
            })),
        );
      }

      if (otherBrandsRules.length === 0) {
        setOtherBrandsRules(
          brands
            .filter(
              (brand) =>
                ownBrands.length === 0 ||
                initialState.businessType === "group" ||
                !initialState.businessUnits[0].ownBrands.some(
                  (ownBrand) => ownBrand.id === brand.id,
                ),
            )
            .map((brand) => ({
              id: uuid(),
              brand,
              noOffer: false,
              specificRules: [],
              discount: initialState.discountOtherBrands,
              appliesTo: [],
            })),
        );
      }
    },
  });

  const filteredOwnBrandsRules = ownBrandsRules.filter(
    (rule) =>
      !search ||
      !search.trim() ||
      rule.brand.name.toLowerCase().includes(search.trim().toLowerCase()),
  );

  const filteredOtherBrandsRules = otherBrandsRules.filter(
    (rule) =>
      !search ||
      !search.trim() ||
      rule.brand.name.toLowerCase().includes(search.trim().toLowerCase()),
  );

  return (
    <GettingStartedLayout
      lg={mode === "advanced" ? 6 : 5}
      xl={mode === "advanced" ? 5 : 4}
      image="/market-intelligence.webp"
      noImageTransformations
      flexStart
      noBackgroundLogo
      fullWidthImage
      nextStep={() => nextStep({ ownBrandsRules, otherBrandsRules, mode })}
      prevStep={() => prevStep({ ownBrandsRules, otherBrandsRules, mode })}
      disabled={[...ownBrandsRules, ...otherBrandsRules].some(
        (rule) => !rule.noOffer && (!rule.discount || rule.discount < 0),
      )}
    >
      <Box>
        <Typography variant="h5">
          Configura tus ofertas
          <BusinessIntelligenceDialog trigger="button" />
        </Typography>
        <Typography>
          Pre-llenamos esta sección basándonos en tus respuestas anteriores,
          puedes hacer ajustes individuales en los descuentos que haces para
          comprar autos, a partir del valor promedio de mercado, para marcas o
          modelos específicos.
        </Typography>
      </Box>
      <Box>
        <TextField
          variant="outlined"
          fullWidth
          autoFocus
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          placeholder="Buscar marcas"
        />
      </Box>
      <Box>
        {loading && <CircularProgress size={64} />}

        {!loading && (
          <GroupedVirtuoso
            groupCounts={
              ownBrandsRules.length
                ? [
                    filteredOwnBrandsRules.length,
                    filteredOtherBrandsRules.length,
                  ]
                : [filteredOtherBrandsRules.length]
            }
            style={{ height: 520 }}
            groupContent={(idx) => (
              <ListSubheader>
                {
                  (ownBrandsRules.length
                    ? initialState.businessType === "group"
                      ? [
                          "En Concesionarios de la misma marca",
                          "En Concesionarios de otras marcas",
                        ]
                      : ["En las marcas de tu Concesionario", "En otras marcas"]
                    : ["Marcas"])[idx]
                }
              </ListSubheader>
            )}
            itemContent={(idx, groupIdx) => {
              const type =
                groupIdx === 0 && ownBrandsRules.length
                  ? "ownBrandsRules"
                  : "otherBrandsRules";

              const rule =
                type === "ownBrandsRules"
                  ? filteredOwnBrandsRules[idx]
                  : filteredOtherBrandsRules[
                      idx - filteredOwnBrandsRules.length
                    ];

              if (!rule) return;

              return (
                <Rule
                  key={rule.id}
                  rule={rule}
                  onChange={async (newRule) => {
                    const newRules = { ownBrandsRules, otherBrandsRules }[
                      type
                    ].map((oldRule) =>
                      oldRule.id === rule.id ? newRule : oldRule,
                    );
                    await saveState({ [type]: newRules });
                    if (type === "ownBrandsRules") setOwnBrandsRules(newRules);
                    if (type === "otherBrandsRules")
                      setOtherBrandsRules(newRules);
                  }}
                  mode={mode}
                  saving={saving}
                  setSaving={setSaving}
                />
              );
            }}
          />
        )}
      </Box>
      <Box>
        <FormControlLabel
          control={
            saving === "mode" ? (
              <CircularProgress size={24} sx={{ mx: 2.125 }} />
            ) : (
              <Switch
                checked={mode === "advanced"}
                onChange={async (e) => {
                  setSaving("mode");
                  const newMode = e.target.checked ? "advanced" : "basic";
                  setMode(newMode);
                  await saveState({ mode: newMode });
                  setSaving(null);
                }}
                disabled={Boolean(saving)}
              />
            )
          }
          label={
            <Box>
              <Typography variant="body2">
                Agregar ofertas diferentes por modelo.
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Esta es una configuración avanzada,&nbsp;
                <Link
                  href={`https://wa.me/5215570058768?text=${encodeURIComponent(
                    "Hola, necesito ayuda en mi proceso de afiliación configurando mis porcentajes de descuento para mis ofertas.",
                  )}`}
                  target="_blank"
                >
                  ¿necesitas ayuda?
                </Link>
              </Typography>
            </Box>
          }
        />
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedRules;
