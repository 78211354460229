import {
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
  Grow,
  Radio,
  Skeleton,
} from "@mui/material";
import { useEffect, useState } from "react";

function GooglePlace({ autocompletePlace, selected, select }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [detailedPlace, setDetailedPlace] = useState(null);
  const [geometry, setGeometry] = useState(null);

  useEffect(() => {
    async function fetchDetailedPlace() {
      const { PlacesService } = await window.google.maps.importLibrary(
        "places"
      );
      const placesService = new PlacesService(
        document.querySelector("#places-attrs")
      );

      const { Geocoder } = await window.google.maps.importLibrary("geocoding");
      const geocoder = new Geocoder();

      placesService.getDetails(
        {
          placeId: autocompletePlace.place_id,
          fields: [
            "address_components",
            "formatted_address",
            "international_phone_number",
            "name",
            "opening_hours",
            "photos",
            "place_id",
            "rating",
            "website",
          ],
          language: "es-419",
        },
        (place) => {
          geocoder.geocode({ placeId: place.place_id }, ([{ geometry }]) => {
            setGeometry(geometry);
            setDetailedPlace(place);
          });
        }
      );
    }
    fetchDetailedPlace();
  }, [autocompletePlace]);

  return (
    <Grow in={imageLoaded}>
      <Card variant="outlined" sx={{ width: "100%" }}>
        <CardActionArea
          onClick={() => select({ ...detailedPlace, ...geometry })}
          disabled={!detailedPlace}
        >
          {detailedPlace ? (
            <CardMedia
              component="img"
              width="100%"
              height="160"
              image={
                detailedPlace.photos && detailedPlace.photos.length
                  ? detailedPlace.photos[0].getUrl()
                  : `https://maps.googleapis.com/maps/api/staticmap?center=${geometry.location.lat()},${geometry.location.lng()}&zoom=19&size=640x320&maptype=roadmap&markers=color:red%7C${geometry.location.lat()},${geometry.location.lng()}&key=AIzaSyBkaJj4POkpHZ0zs8obzrxQ9pz2M46UTw8`
              }
              alt={autocompletePlace.structured_formatting.main_text}
              onLoad={() => setImageLoaded(true)}
            />
          ) : (
            <Skeleton variant="rectangular" width="100%" height={160} />
          )}
          <CardHeader
            sx={{ alignItems: "flex-start" }}
            title={<b>{autocompletePlace.structured_formatting.main_text}</b>}
            subheader={autocompletePlace.structured_formatting.secondary_text}
            avatar={<Radio checked={selected || false} disableRipple />}
          />
        </CardActionArea>
      </Card>
    </Grow>
  );
}

export default GooglePlace;
