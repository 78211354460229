import {
  DeleteOutline,
  FileDownload,
  PictureAsPdf,
  PreviewOutlined,
  UploadFile,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { gql, useMutation } from "@apollo/client";
import { Document, Page } from "react-pdf";

import { useEffect, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { useSnackbar } from "notistack";

const UPLOAD_FILE = gql`
  mutation UploadFile($file: String!, $extension: String!) {
    uploadedFile: uploadFile(file: $file, extension: $extension)
  }
`;

function PDF({
  url: inputUrl,
  onChange,
  filename,
  businessName,
  variant = "default",
}) {
  const [authToken] = useLocalStorage("authToken", null);
  const [upload] = useMutation(UPLOAD_FILE, {
    context: { headers: { Authorization: ["Bearer", authToken].join(" ") } },
  });
  const [preview, setPreview] = useState(null);
  const [url, setUrl] = useState(inputUrl);
  const [pages, setPages] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  useEffect(() => setUrl(inputUrl), [inputUrl]);

  const src = (preview || url || "").replace(
    "https://intelimotor.s3.amazonaws.com/",
    "https://app.intelimotor.com/api/uploads/",
  );

  function handleRemove() {
    setUrl(null);
    setPreview(null);
    onChange(null);
  }

  const PDFDocument = () => (
    <Document
      file={src}
      onLoadSuccess={({ numPages }) => setPages(numPages)}
      loading={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 300,
          }}
        >
          <CircularProgress size={48} />
        </Box>
      }
    >
      {[...Array(pages)]
        .map((x, i) => i + 1)
        .map((page) => (
          <Page
            key={page}
            pageNumber={page}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        ))}
    </Document>
  );

  const FileInput = () => (
    <Box
      component="input"
      type="file"
      label="pdf"
      sx={{ display: "none" }}
      accept="application/pdf"
      onChange={(e) => {
        const [file] = e.target.files;
        if (file) {
          if (file.type !== "application/pdf") {
            enqueueSnackbar("Solo se admiten archivos en formato PDF", {
              variant: "error",
            });
            return;
          }

          // preview
          const preview = URL.createObjectURL(file);
          setPreview(preview);

          // upload
          const fileReader = new FileReader();
          fileReader.addEventListener("load", (e) => {
            upload({
              variables: { file: e.target.result, extension: "pdf" },
              onCompleted({ uploadedFile }) {
                setUrl(uploadedFile);
                onChange(uploadedFile);
              },
            });
          });
          fileReader.readAsDataURL(file);
        }
      }}
    />
  );

  if (variant === "buttons") {
    return (
      <>
        {!src && (
          <>
            <IconButton component="label" labelFor="pdf">
              <UploadFile />
              <FileInput />
            </IconButton>
          </>
        )}

        {src && !url && <CircularProgress size={24} sx={{ mr: 1 }} />}

        {url && (
          <>
            <IconButton onClick={() => setOpen(true)}>
              <PreviewOutlined />
            </IconButton>

            <IconButton onClick={handleRemove}>
              <DeleteOutline />
            </IconButton>
          </>
        )}

        <Dialog
          open={open}
          fullWidth
          maxWidth="md"
          onClose={() => setOpen(false)}
        >
          <DialogTitle>
            {`Constancia de Situación Fiscal de ${businessName}`}
          </DialogTitle>
          <DialogContent
            sx={{ position: "relative", minHeight: 300, maxHeight: 500, p: 0 }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 8,
                right: 24,
                zIndex: 1,
              }}
            >
              <IconButton component="a" href={url} download={filename}>
                <FileDownload />
              </IconButton>
            </Box>

            <PDFDocument />
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={() => setOpen(false)}>
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <>
      {Boolean(src) && (
        <Card>
          <Box
            sx={{
              position: "relative",
              overflowY: "auto",
              maxHeight: "500px",
              minHeight: "300px",
            }}
          >
            {!url && (
              <LinearProgress
                color="secondary"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: 8,
                  zIndex: 1,
                }}
              />
            )}

            <PDFDocument />
          </Box>
          {url && (
            <>
              <Divider />
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button
                  onClick={handleRemove}
                  variant="outlined"
                  color="inherit"
                  sx={{ mr: 1 }}
                >
                  Eliminar
                </Button>

                <Button
                  variant="contained"
                  component="a"
                  href={url}
                  download={filename}
                >
                  Descargar
                </Button>
              </CardActions>
            </>
          )}
        </Card>
      )}

      {!src && (
        <Button component="label" labelFor="pdf" variant="contained">
          <PictureAsPdf sx={{ mr: 1 }} />
          Seleccionar archivo
          <FileInput />
        </Button>
      )}
    </>
  );
}

export default PDF;
