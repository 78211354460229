import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import useLocalStorage from "../hooks/useLocalStorage";
import GettingStartedWelcome from "./GettingStartedWelcome";
import GettingStartedBusinessType from "./GettingStartedBusinessType";
import GettingStartedBusinessName from "./GettingStartedBusinessName";
import GettingStartedLocations from "./GettingStartedLocations";
import GettingStartedInspectionTypes from "./GettingStartedInspectionTypes";
import GettingStartedPaymentTypes from "./GettingStartedPaymentTypes";
import GettingStartedExchanges from "./GettingStartedExchanges";
import GettingStartedNotFullyPaid from "./GettingStartedNotFullyPaid";
import GettingStartedTimeToPayment from "./GettingStartedTimeToPayment";
import GettingStartedMinYear from "./GettingStartedMinYear";
import GettingStartedMaxKms from "./GettingStartedMaxKms";
import GettingStartedDiscountOtherBrands from "./GettingStartedDiscountOtherBrands";
import GettingStartedDiscountOwnBrands from "./GettingStartedDiscountOwnBrands";
import GettingStartedRules from "./GettingStartedRules";
import GettingStartedFreeformRules from "./GettingStartedFreeformRules";
import GettingStartedVerification from "./GettingStartedVerification";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import GettingStartedInspectionDuration from "./GettingStartedInspectionDuration";
import GettingStartedDone from "./GettingStartedDone";
import toPercentage from "../utils/toPercentage";
import WhatsAppButton from "./WhatsAppButton";
import ReactGA from "react-ga4";
import {
  ChevronRight,
  CloudDoneOutlined,
  ExitToAppOutlined,
  MenuOpen,
} from "@mui/icons-material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useTheme } from "@emotion/react";
import { jwtDecode } from "jwt-decode";
import GettingStartedProofOfTaxId from "./GettingStartedProofOfTaxId";
import GettingStartedOverstockExceptions from "./GettingStartedOverstockExceptions";
import { Link as RouterLink } from "react-router-dom";
import GettingStartedConsignments from "./GettingStartedConsignments";
import GettingStartedConsignmentsFees from "./GettingStartedConsignmentsFees";
import GettingStartedConsignmentsRequirements from "./GettingStartedConsignmentsRequirements";
import GettingStartedConsignmentsMinYear from "./GettingStartedConsignmentsMinYear";
import GettingStartedConsignmentsMaxKms from "./GettingStartedConsignmentsMaxKms";
import GettingStartedConsignmentsRules from "./GettingStartedConsignmentsRules";

export const GET_REMOTE_GETTING_STARTED_STATE = gql`
  query GetRemoteState {
    me {
      name
      email
      ventauGettingStartedState
      company {
        liteType
      }
    }
  }
`;

export const GET_REMOTE_SETTINGS_STATE = gql`
  query GetRemoteState {
    me {
      name
      email
      company {
        liteType
        ventauSettings
      }
    }
  }
`;

export const SAVE_REMOTE_STATE = gql`
  mutation SaveRemoteState($state: String!, $variant: String) {
    changeVentauGettingStartedState(state: $state, variant: $variant)
  }
`;

function GettingStarted() {
  const [searchParams] = useSearchParams();
  const [authToken, setAuthToken] = useLocalStorage("authToken", null);
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [maxStep, setMaxStep] = useState(step);
  const [lastStep, setLastStep] = useState(null);
  const [gettingStartedState, setGettingStartedState] = useState({});
  const [accountType, setAccountType] = useState("ventau");
  const [savedAt, setSavedAt] = useState(moment());
  const [prevStepsMenuTarget, setPrevStepsMenuTarget] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isMaster, setIsMaster] = useState(false);
  const [ownProfile, setOwnProfile] = useState(null);

  const variant = searchParams.get("variant") || "setup";

  const [getRemoteState] = useLazyQuery(
    variant === "settings"
      ? GET_REMOTE_SETTINGS_STATE
      : GET_REMOTE_GETTING_STARTED_STATE,
    {
      onCompleted({ me }) {
        if (!me) {
          setAuthToken(null);
          setTimeout(() => navigate("/"), 100);
          return;
        }

        const remoteState = JSON.parse(
          variant === "settings"
            ? me.company.ventauSettings
            : me.ventauGettingStartedState,
        );
        setGettingStartedState(remoteState);
        setStep(
          variant === "setup" ? remoteState.step || 1 : Number(!isMobile),
        );
        setMaxStep(remoteState.maxStep || remoteState.step || 1);
        setAccountType(me.company.liteType || "premium");
        setOwnProfile({ name: me.name, email: me.email });

        if (!remoteState.step) {
          ReactGA.event({
            category: "getting-started",
            action: "first-step",
          });
        }
      },
    },
  );

  useEffect(() => {
    const token = searchParams.get("authToken") || authToken;
    if (token) {
      setAuthToken(token);
      getRemoteState({
        context: {
          headers: {
            Authorization: ["Bearer", token].join(" "),
          },
        },
      });

      const decodedToken = jwtDecode(token);
      setIsMaster(decodedToken.dat.master || false);
    } else {
      setTimeout(() => navigate("/"), 100);
    }
  }, [authToken, searchParams, getRemoteState, setAuthToken, navigate]);

  const [saveRemoteState] = useMutation(SAVE_REMOTE_STATE, {
    context: { headers: { Authorization: ["Bearer", authToken].join(" ") } },
  });

  const steps = useMemo(
    () =>
      [
        ...(variant === "setup"
          ? [
              {
                name: "Welcome",
                component: GettingStartedWelcome,
                displayName: "Bienvenido",
              },
              {
                name: "BusinessType",
                component: GettingStartedBusinessType,
                displayName: "Tipo de negocio",
              },
              {
                name: "BusinessName",
                component: GettingStartedBusinessName,
                displayName: "Nombre de tu negocio",
              },
            ]
          : []),
        {
          name: "Locations",
          component: GettingStartedLocations,
          displayName:
            gettingStartedState.businessType === "group"
              ? "Perfiles de concesionarios"
              : "Perfil de concesionario",
        },
        {
          name: "InspectionTypes",
          component: GettingStartedInspectionTypes,
          displayName: "Tipos de inspección",
        },
        {
          name: "InspectionDuration",
          component: GettingStartedInspectionDuration,
          displayName: "Duración de inspecciones",
        },
        {
          name: "Exchanges",
          component: GettingStartedExchanges,
          displayName: "Autos a cuenta",
        },
        {
          name: "NotFullyPaid",
          component: GettingStartedNotFullyPaid,
          displayName: "Autos con financiamiento",
        },
        {
          name: "PaymentTypes",
          component: GettingStartedPaymentTypes,
          displayName: "Tipos de pago",
        },
        {
          name: "TimeToPayment",
          component: GettingStartedTimeToPayment,
          displayName: "Tiempo de pago",
        },
        {
          name: "MinYear",
          component: GettingStartedMinYear,
          displayName: "Año mínimo",
        },
        {
          name: "MaxKms",
          component: GettingStartedMaxKms,
          displayName: "Kilometraje máximo",
        },
        {
          name: "DiscountOwnBrands",
          component: GettingStartedDiscountOwnBrands,
          displayName:
            gettingStartedState.businessType === "group"
              ? "Descuento en las marcas de tus concesionarios"
              : "Descuento en las marcas de tu concesionario",
        },
        {
          name: "DiscountOtherBrands",
          component: GettingStartedDiscountOtherBrands,
          displayName: "Descuento en otras marcas",
        },
        {
          name: "Rules",
          component: GettingStartedRules,
          displayName: "Configura tus ofertas",
        },
        {
          name: "OverstockExceptions",
          component: GettingStartedOverstockExceptions,
          displayName: "Excepciones de inventario excedente",
        },
        {
          name: "Consignments",
          component: GettingStartedConsignments,
          displayName: "Servicio de consignación",
        },
        {
          name: "ConsignmentsFees",
          component: GettingStartedConsignmentsFees,
          displayName: "Comision para consignación",
        },
        {
          name: "ConsignmentsRequirements",
          component: GettingStartedConsignmentsRequirements,
          displayName: "Consignación física requerida",
        },
        {
          name: "ConsignmentsMinYear",
          component: GettingStartedConsignmentsMinYear,
          displayName: "Año mínimo para consignación",
        },
        {
          name: "ConsignmentsMaxKms",
          component: GettingStartedConsignmentsMaxKms,
          displayName: "Kilometraje máximo para consignación",
        },
        {
          name: "ConsignmentsRules",
          component: GettingStartedConsignmentsRules,
          displayName: "Exclusiones de consignación",
        },
        ...(variant === "setup"
          ? [
              {
                name: "FreeformRules",
                component: GettingStartedFreeformRules,
                displayName: "Otras reglas de oferta",
              },
              {
                name: "ProofOfTaxId",
                component: GettingStartedProofOfTaxId,
                displayName: "Constancia de situación fiscal",
              },
              {
                name: "Verification",
                component: GettingStartedVerification,
                displayName: "Verificación de negocio",
              },
              {
                name: "Done",
                component: GettingStartedDone,
              },
            ]
          : []),
      ].filter(
        (item) =>
          accountType === "ventau" ||
          !["BusinessType", "BusinessName"].includes(item.name),
      ),
    [accountType, gettingStartedState.businessType, variant],
  );

  async function saveState(data = {}, refetch = false) {
    const newState = { ...gettingStartedState, ...data };
    setSavedAt(null);
    setGettingStartedState(newState);
    const result = await saveRemoteState({
      variables: { state: JSON.stringify(newState), variant },
    });

    if (refetch) {
      await getRemoteState({
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: ["Bearer", authToken].join(" "),
          },
        },
      });
    }

    setSavedAt(moment());
    return result;
  }

  async function nextStep(data = {}, stepsForward = 1, refetch = false) {
    setLastStep(step);
    const newStep = variant === "setup" ? step + stepsForward : step;
    const newMaxStep = Math.max(newStep, maxStep);
    const result = await saveState(
      {
        ...gettingStartedState,
        ...data,
        step: newStep,
        stepName: steps[newStep - 1]?.name,
        maxStep: newMaxStep,
      },
      refetch,
    );
    setStep(newStep);
    setMaxStep(newMaxStep);

    if (steps[newStep - 1]?.name) {
      ReactGA.event({
        category: "getting-started",
        action: "next-step",
        label: steps[newStep - 1]?.name,
      });
    }

    if (variant === "settings") {
      enqueueSnackbar("Se guardó la configuración.", { variant: "success" });
    }

    return result;
  }

  async function prevStep(data = {}, stepsBackwards = 1, refetch = false) {
    setLastStep(step);
    const newStep = step - stepsBackwards;
    let result = null;
    if (variant === "setup") {
      result = await saveState(
        {
          ...gettingStartedState,
          ...data,
          step: newStep,
          stepName: steps[newStep - 1]?.name,
        },
        refetch,
      );
    }
    setStep(newStep);

    if (steps[newStep - 1]?.name) {
      ReactGA.event({
        category: "getting-started",
        action: "prev-step",
        label: steps[newStep - 1]?.name,
      });
    }
    return result;
  }

  const StepComponent = step > 0 ? steps[step - 1].component : <></>;

  if (variant === "settings") {
    return (
      <Container sx={{ py: 4 }}>
        <Grid container spacing={4}>
          {(!isMobile || step === 0) && (
            <Grid item xs={12} md={4} xl={3}>
              <Paper>
                <List>
                  {steps
                    .filter((item) => item.displayName)
                    .map((item, idx) => (
                      <Fragment key={item.name}>
                        {idx > 0 && <Divider />}
                        <MenuItem
                          onClick={() => {
                            setPrevStepsMenuTarget(null);
                            prevStep({}, step - idx - 1, false);
                          }}
                          selected={idx === step - 1}
                          sx={{
                            whiteSpace: "break-spaces",
                            ...(isMobile ? { pr: 6 } : {}),
                          }}
                        >
                          <ListItemText primary={item.displayName} />
                          {isMobile && (
                            <ListItemSecondaryAction>
                              <IconButton disableRipple>
                                <ChevronRight />
                              </IconButton>
                            </ListItemSecondaryAction>
                          )}
                        </MenuItem>
                      </Fragment>
                    ))}
                </List>
              </Paper>
            </Grid>
          )}
          {step > 0 && (
            <Grid item xs={12} md={8} xl={9}>
              {isMobile && (
                <Box sx={{ mb: 2 }}>
                  <Link
                    sx={{ textDecoration: "none" }}
                    onClick={() => setStep(0)}
                  >
                    &lt; Regresar
                  </Link>
                </Box>
              )}
              <StepComponent
                initialState={gettingStartedState}
                prevStep={prevStep}
                nextStep={nextStep}
                saveState={saveState}
                lastStep={steps[lastStep - 1]?.name}
                ownProfile={ownProfile}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    );
  }

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Container maxWidth="xl" sx={{ minHeight: "calc(100vh - 10px)" }}>
        <Stack spacing={6} width="100%" py={6}>
          <Box textAlign="center">
            <Box component="img" src="/logo.webp" alt="Ventau" maxWidth={240} />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {isMaster && (
              <Tooltip title="Cerrar sesión">
                <IconButton component={RouterLink} to="/sign-out">
                  <ExitToAppOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {step > 0 &&
              ((steps[step - 1].name !== "Done" &&
                !gettingStartedState.rescheduling) ||
                isMaster) && (
                <>
                  <Tooltip
                    title={
                      step === maxStep
                        ? "Regresar a un paso anterior"
                        : "Navegar a otro paso"
                    }
                  >
                    <IconButton
                      onClick={(e) => setPrevStepsMenuTarget(e.currentTarget)}
                    >
                      <MenuOpen />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    open={Boolean(prevStepsMenuTarget)}
                    anchorEl={prevStepsMenuTarget}
                    onClose={() => setPrevStepsMenuTarget(null)}
                  >
                    <Box sx={{ px: 2, pb: 1 }}>
                      <Typography variant="caption" color="textSecondary">
                        {step === maxStep
                          ? "No te preocupes, tu progreso no se perderá al regresar."
                          : "No te preocupes, tu progreso no se perderá al navegar."}
                      </Typography>
                    </Box>

                    {steps
                      .filter((item) => item.displayName)
                      .map((item, idx) => (
                        <MenuItem
                          key={item.name}
                          onClick={() => {
                            setPrevStepsMenuTarget(null);
                            prevStep({}, step - idx - 1, false);
                          }}
                          disabled={maxStep <= idx}
                          selected={idx === step - 1}
                        >
                          <ListItemIcon>{idx + 1}</ListItemIcon>
                          {item.displayName}
                        </MenuItem>
                      ))}
                  </Menu>
                </>
              )}
            <LinearProgress
              sx={{ flex: 1, height: 10, borderRadius: 2, mr: 1 }}
              variant="determinate"
              value={(100 / steps.length) * step}
              color="inherit"
            />
            {step > 0 && (
              <Typography variant="subtitle2">
                {toPercentage(((100 / steps.length) * step) / 100, 0)}
              </Typography>
            )}
            <Box sx={{ pl: 1, display: "flex", alignItems: "center" }}>
              <Tooltip title={savedAt ? `Progreso guardado` : null}>
                {savedAt ? (
                  <CloudDoneOutlined fontSize="small" color="secondary" />
                ) : (
                  <CircularProgress color="inherit" size={20} />
                )}
              </Tooltip>
            </Box>
          </Box>
          {step === 0 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: 400,
              }}
            >
              <CircularProgress size={48} />
            </Box>
          ) : (
            <StepComponent
              initialState={gettingStartedState}
              prevStep={prevStep}
              nextStep={nextStep}
              saveState={saveState}
              lastStep={steps[lastStep - 1]?.name}
              ownProfile={ownProfile}
            />
          )}
        </Stack>
      </Container>
      <WhatsAppButton />
    </Box>
  );
}

export default GettingStarted;
