import {
  AddBusiness,
  DeleteOutline,
  EditOutlined,
  QuestionMark,
  WarningOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { v4 as uuid } from "uuid";

import BusinessUnit from "./BusinessUnit";
import GettingStartedLayout from "./GettingStartedLayout";
import { useSearchParams } from "react-router-dom";

function GettingStartedLocationsMultiple({
  initialState,
  nextStep,
  prevStep,
  saveState,
  ownProfile,
}) {
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [businessUnits, setBusinessUnits] = useState(
    initialState.businessUnits || [],
  );
  const [openBusinessUnit, setOpenBusinessUnit] = useState(null);
  const [openBusinessUnitFirstStep, setOpenBusinessUnitFirstStep] = useState(0);
  const [saving, setSaving] = useState(false);

  const variant = searchParams.get("variant") || "setup";

  return (
    <>
      <GettingStartedLayout
        image="/sedan-blanco.png"
        nextStep={() =>
          nextStep({
            businessUnits,
          })
        }
        prevStep={() =>
          prevStep({
            businessUnits,
          })
        }
        disabled={
          businessUnits.length === 0 ||
          !businessUnits.some((businessUnit) => businessUnit.googlePlace)
        }
        flexStart
      >
        <Box sx={{ maxWidth: 500 }}>
          <Typography variant="h5">Agrega tus Concesionarios</Typography>
          <Typography>
            Estos serán los puntos de inspección donde los vendedores
            particulares acudirán para concretar la venta de sus autos.
          </Typography>
        </Box>
        <Box sx={{ maxWidth: 500 }}>
          <Paper variant="outlined">
            <List>
              {businessUnits.map((businessUnit, idx) => (
                <>
                  <ListItem
                    sx={{
                      paddingRight: businessUnit.googlePlace
                        ? 12
                        : isMobile
                        ? 8
                        : 23,
                      alignItems: "flex-start",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={businessUnit.storefront}
                        variant="rounded"
                        sx={{ width: 60, height: 60, mr: 1.5, mt: 1 }}
                      >
                        <QuestionMark />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={businessUnit.businessName}
                      secondary={
                        <>
                          {businessUnit.googlePlace ? (
                            businessUnit.googlePlace.formatted_address
                          ) : isMobile ? (
                            <Button
                              variant="contained"
                              onClick={() => {
                                setOpenBusinessUnitFirstStep(0);
                                setOpenBusinessUnit(businessUnit);
                              }}
                              size="small"
                              sx={{ mt: 1 }}
                            >
                              Completar perfil
                            </Button>
                          ) : (
                            "Perfil incompleto"
                          )}
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      {businessUnit.googlePlace && (
                        <IconButton
                          onClick={() => {
                            setOpenBusinessUnitFirstStep(
                              variant === "setup" ? 8 : 7,
                            );
                            setOpenBusinessUnit(businessUnit);
                          }}
                        >
                          <EditOutlined />
                        </IconButton>
                      )}
                      {!businessUnit.googlePlace && !isMobile && (
                        <Button
                          variant="contained"
                          onClick={() => {
                            setOpenBusinessUnitFirstStep(0);
                            setOpenBusinessUnit(businessUnit);
                          }}
                          size="small"
                        >
                          Completar perfil
                        </Button>
                      )}
                      <Tooltip
                        title={
                          businessUnit.resetting
                            ? "Al eliminar este Concesionario mantendrás su configuración actual."
                            : null
                        }
                      >
                        <IconButton
                          onClick={async () => {
                            const newBusinessUnits = businessUnits.filter(
                              (item, i) => i !== idx,
                            );
                            setSaving(idx);
                            await saveState({
                              businessUnits: newBusinessUnits,
                            });
                            setBusinessUnits(newBusinessUnits);
                            setSaving(false);
                          }}
                          disabled={saving === idx}
                        >
                          {saving === idx ? (
                            <CircularProgress size={24} color="error" />
                          ) : (
                            <DeleteOutline />
                          )}
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                  {businessUnit.resetting && (
                    <ListItem sx={{ pt: 0 }}>
                      <WarningOutlined color="warning" sx={{ mr: 1 }} />
                      <ListItemText
                        secondary={
                          <>
                            Estás sobrescribiendo la configuración de este
                            Concesionario.{" "}
                            <Link
                              href="#"
                              onClick={async () => {
                                const newBusinessUnits = businessUnits.filter(
                                  (item, i) => i !== idx,
                                );
                                setSaving(idx);
                                await saveState({
                                  businessUnits: newBusinessUnits,
                                });
                                setBusinessUnits(newBusinessUnits);
                                setSaving(false);
                              }}
                              disabled={saving === idx}
                            >
                              Mantener la configuracón actual
                            </Link>
                            .
                          </>
                        }
                      />
                    </ListItem>
                  )}
                  <Divider />
                </>
              ))}
              <ListItem
                button
                onClick={() => {
                  setOpenBusinessUnitFirstStep(0);
                  setOpenBusinessUnit({ id: uuid() });
                }}
              >
                <ListItemIcon>
                  <AddBusiness />
                </ListItemIcon>
                <ListItemText
                  primary={
                    businessUnits.length === 0
                      ? "Agrega tu primer Concesionario a Ventau"
                      : "Agrega otro Concesionario a Ventau"
                  }
                />
              </ListItem>
            </List>
          </Paper>
        </Box>
      </GettingStartedLayout>

      {openBusinessUnit && (
        <Dialog
          open={Boolean(openBusinessUnit)}
          fullWidth
          maxWidth="md"
          onClose={() => (!saving ? setOpenBusinessUnit(null) : null)}
        >
          <DialogContent>
            {/* googlePlace, schedule, storefront */}
            <BusinessUnit
              businessUnit={openBusinessUnit}
              onChange={(data) => setOpenBusinessUnit(data)}
              variant="dialog"
              firstStep={openBusinessUnitFirstStep}
              nextStep={async () => {
                const newBusinessUnits = businessUnits.some(
                  (businessUnit) => businessUnit.id === openBusinessUnit.id,
                )
                  ? businessUnits.map((businessUnit) =>
                      businessUnit.id === openBusinessUnit.id
                        ? openBusinessUnit
                        : businessUnit,
                    )
                  : [...businessUnits, openBusinessUnit];
                setSaving(true);
                await saveState({ businessUnits: newBusinessUnits });
                setSaving(false);
                setBusinessUnits(newBusinessUnits);
                setOpenBusinessUnit(null);
              }}
              ownProfile={ownProfile}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default GettingStartedLocationsMultiple;
