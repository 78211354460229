function predictBrands(businessNames, brands) {
  const businessNamesWords = businessNames.reduce(
    (words, businessName) => [
      ...words,
      ...businessName.toLowerCase().split(" "),
    ],
    [],
  );

  const identifiedBrands = brands.filter((brand) => {
    let { name } = brand;
    if (name === "Chevrolet") name = [name, "Chevy"].join(" ");
    if (name === "Kia") name = [name, "Konfidence"].join(" ");
    if (name === "Volkswagen")
      name = [
        name,
        "Das",
        "Welt",
        "Auto",
        "Daswelt",
        "Weltauto",
        "Dasweltauto",
      ].join(" ");
    return name
      .toLowerCase()
      .split(" ")
      .some((brandWord) => businessNamesWords.includes(brandWord));
  });

  return identifiedBrands;
}

export default predictBrands;
