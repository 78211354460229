import {
  Cancel,
  Check,
  CheckCircle,
  Clear,
  DeleteOutline,
  EditOutlined,
  ExpandMore,
  HelpOutline,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { v4 as uuid } from "uuid";

import toPercentage from "../utils/toPercentage";
import RuleDialog from "./RuleDialog";
import SpecificRuleDialog from "./SpecificRuleDialog";

function RuleWrapper({
  mode,
  children,
  rule,
  onChange,
  setOpenSpecificRule,
  saving,
  setSaving,
  variant,
}) {
  const specificRules = rule.specificRules || [];

  if (mode === "advanced") {
    return (
      <Accordion variant="outlined">
        <AccordionSummary expandIcon={<ExpandMore />} sx={{ pl: 0, pr: 2 }}>
          {children}
        </AccordionSummary>
        <AccordionDetails>
          <Paper variant="outlined">
            <List>
              {specificRules.map((specificRule) => (
                <ListItem key={specificRule.id}>
                  <ListItemIcon>
                    {specificRule.noOffer ? (
                      <Cancel color="error" />
                    ) : (
                      <CheckCircle color="success" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={specificRule.models
                      .map((model) => model.name)
                      .join(", ")}
                    secondary={
                      specificRule.noOffer
                        ? variant === "consignments"
                          ? "No aceptar a consignación"
                          : "No comprar"
                        : variant === "consignments"
                          ? "Si aceptar a consignación"
                          : toPercentage(specificRule.discount / 100)
                    }
                  />
                  <IconButton
                    onClick={() => setOpenSpecificRule(specificRule)}
                    disabled={Boolean(saving)}
                  >
                    <EditOutlined />
                  </IconButton>
                  <IconButton
                    onClick={async () => {
                      setSaving(specificRule.id);
                      await onChange({
                        ...rule,
                        specificRules: specificRules.filter(
                          (item) => item.id !== specificRule.id,
                        ),
                      });
                      setSaving(null);
                    }}
                    disabled={Boolean(saving)}
                  >
                    {saving === specificRule.id ? (
                      <CircularProgress size={24} sx={{ ml: 2.5, mr: 0.75 }} />
                    ) : (
                      <DeleteOutline />
                    )}
                  </IconButton>
                </ListItem>
              ))}

              <ListItem>
                <ListItemText
                  secondary={
                    specificRules.length === 0
                      ? variant === "consignments"
                        ? "No has configurado una excepción de toma a consignación por modelo en esta marca."
                        : "No has agregado ofertas por modelo en esta marca."
                      : "."
                  }
                  sx={{ mr: "48px" }}
                />
                <ListItemSecondaryAction>
                  <Button
                    variant="contained"
                    className={saving ? "" : "gradient"}
                    size="small"
                    onClick={() =>
                      setOpenSpecificRule({
                        id: uuid(),
                        models: [],
                        discount: null,
                        noOffer: variant === "consignments",
                      })
                    }
                    disabled={Boolean(saving)}
                    sx={{ ml: 1 }}
                  >
                    Agregar
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Paper>
        </AccordionDetails>
      </Accordion>
    );
  }

  return <>{children}</>;
}

function RuleBadge({ mode, noOffer, children }) {
  if (mode === "advanced") {
    return (
      <Badge
        color={noOffer ? "error" : "success"}
        badgeContent={
          noOffer ? (
            <Clear sx={{ fontSize: 18 }} />
          ) : (
            <Check sx={{ fontSize: 18 }} />
          )
        }
        componentsProps={{
          root: {
            style: { zIndex: 0 },
          },
          badge: {
            style: {
              width: 20,
              height: 20,
              borderRadius: 12,
              transform: "scale(1) translate(50%, 100%)",
            },
          },
        }}
      >
        {children}
      </Badge>
    );
  }

  return <>{children}</>;
}

function Rule({ rule, onChange, mode, saving, setSaving, variant }) {
  const [editing, setEditing] = useState(false);
  const [openSpecificRule, setOpenSpecificRule] = useState(null);

  const { brand, noOffer, discount } = rule;

  const logoAttribute = useMemo(
    () => brand.attributes.find((attribute) => attribute.name === "logo"),
    [brand?.id],
  );

  return (
    <>
      <RuleWrapper
        mode={mode}
        rule={rule}
        onChange={onChange}
        setOpenSpecificRule={setOpenSpecificRule}
        saving={saving}
        setSaving={setSaving}
        variant={variant}
      >
        <ListItem>
          <ListItemAvatar sx={{ mr: mode === "advanced" ? 1 : 0 }}>
            <RuleBadge mode={mode} noOffer={noOffer}>
              <Avatar
                variant="rounded"
                src={logoAttribute?.value}
                className={logoAttribute?.value ? "" : "gradient"}
                sx={{ opacity: noOffer && mode === "basic" ? 0.5 : 1 }}
              >
                {brand.name[0].toUpperCase()}
              </Avatar>
            </RuleBadge>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Box sx={{ display: "flex" }}>
                <Typography variant="subtitle2" sx={{ mr: 1 }}>
                  {brand.name}
                </Typography>
                {rule.appliesTo && rule.appliesTo.length > 0 && (
                  <Tooltip
                    title={`Aplica para ${[
                      rule.appliesTo
                        .slice(0, rule.appliesTo.length - 1)
                        .map((businessUnit) => businessUnit.businessName)
                        .join(", "),
                      rule.appliesTo[rule.appliesTo.length - 1].businessName,
                    ]
                      .filter(Boolean)
                      .join(" y ")}.`}
                    componentsProps={{ tooltip: { style: { fontSize: 12 } } }}
                  >
                    <HelpOutline sx={{ fontSize: 16 }} color="action" />
                  </Tooltip>
                )}
              </Box>
            }
            sx={{ opacity: noOffer && mode === "basic" ? 0.5 : 1 }}
            secondary={
              mode === "advanced" ? (
                <>
                  {`${rule.specificRules?.length || 0} ${
                    rule.specificRules?.length === 1
                      ? variant === "consignments"
                        ? "excepción"
                        : "oferta"
                      : variant === "consignments"
                        ? "excepciones"
                        : "ofertas"
                  } por modelo, `}
                  <Link
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenSpecificRule({
                        id: uuid(),
                        models: [],
                        discount: null,
                        noOffer: variant === "consignments",
                      });
                    }}
                    disabled={Boolean(saving)}
                  >
                    {rule.specificRules?.length === 0
                      ? "agrega una"
                      : "agrega otra"}
                    .
                  </Link>
                </>
              ) : null
            }
          />
          {/* discount */}
          {!noOffer && variant !== "consignments" && (
            <Typography sx={{ mr: 1 }}>
              {toPercentage(discount / 100)}
            </Typography>
          )}

          {/* edit */}
          {(!noOffer || mode === "advanced") && variant !== "consignments" && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                e.target.blur();
                setEditing(true);
              }}
              disabled={Boolean(saving)}
            >
              <EditOutlined />
            </IconButton>
          )}

          {/* no offer */}
          {(mode === "basic" || variant === "consignments") &&
            saving === rule.id && (
              <CircularProgress size={24} sx={{ ml: 2.5, mr: 0.75 }} />
            )}

          {(mode === "basic" || variant === "consignments") &&
            saving !== rule.id && (
              <Tooltip
                title={
                  variant === "consignments"
                    ? "Puedes deshabilitar las marcas que no quieres aceptar para consignación."
                    : "Puedes deshabilitar las marcas que no quieres comprar."
                }
              >
                <Switch
                  edge="end"
                  checked={!noOffer}
                  onChange={async (e) => {
                    setSaving(rule.id);
                    await onChange({
                      ...rule,
                      noOffer: !e.target.checked,
                    });
                    setSaving(null);
                  }}
                  onClick={(e) => e.stopPropagation()}
                  disabled={Boolean(saving)}
                />
              </Tooltip>
            )}
        </ListItem>
      </RuleWrapper>

      {editing && (
        <RuleDialog
          rule={rule}
          onClose={() => setEditing(false)}
          onChange={async (newRule) => {
            await onChange(newRule);
            setEditing(false);
          }}
        />
      )}

      {openSpecificRule && (
        <SpecificRuleDialog
          rule={rule}
          specificRule={openSpecificRule}
          onClose={() => setOpenSpecificRule(null)}
          onChange={async (newSpecificRule) => {
            await onChange({
              ...rule,
              specificRules: rule.specificRules.some(
                (specificRule) => specificRule.id === newSpecificRule.id,
              )
                ? rule.specificRules.map((specificRule) =>
                    specificRule.id === openSpecificRule.id
                      ? newSpecificRule
                      : specificRule,
                  )
                : [...rule.specificRules, newSpecificRule],
            });
            setOpenSpecificRule(null);
          }}
          variant={variant}
        />
      )}
    </>
  );
}

export default Rule;
