import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
  EmailOutlined,
  PersonAdd,
  RequestQuote,
  VerifiedUser,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Container,
  Fade,
  Grid,
  Hidden,
  InputAdornment,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { isCompanyEmail } from "company-email-validator";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import useLocalStorage from "../hooks/useLocalStorage";
import { useTheme } from "@emotion/react";
import WhatsAppButton from "./WhatsAppButton";
import ReactGA from "react-ga4";

const CHECK_USER_BY_EMAIL = gql`
  query CheckUserByEmail($email: String!) {
    checkUserByEmail(email: $email)
  }
`;

const SAVE_UNQUALIFIED_LEAD = gql`
  mutation SaveUnqualifiedLead($email: String!) {
    saveUnqualifiedLead(email: $email)
  }
`;

function RequestAccess() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [storedEmail, setStoredEmail] = useLocalStorage("storedEmail", "");
  const [email, setEmail] = useState(storedEmail || "");
  const [error, setError] = useState(null);
  const [saveUnqualifiedLead] = useMutation(SAVE_UNQUALIFIED_LEAD);
  const [check, { loading }] = useLazyQuery(CHECK_USER_BY_EMAIL, {
    onCompleted(data) {
      if (!data.checkUserByEmail && !isCompanyEmail(email.trim())) {
        ReactGA.event({
          category: "request-access",
          action: "check-email",
          label: "unqualified",
        });
        saveUnqualifiedLead({ variables: { email } });
        return setError(
          <>
            {`¡Lo sentimos! Por el momento no es posible el registro con correos personales (gmail.com, outlook.com, etc).\n\nEsta herramienta está diseñada exclusivamente para profesionales de la industria automotriz. Te pedimos ingresar una dirección de correo electrónico de trabajo (ej. jaime@autosmexico.com).`
              .split("\n")
              .map((line) => (
                <>
                  <span>{line}</span>
                  <br />
                </>
              ))}
          </>,
        );
      }

      ReactGA.event({
        category: "request-access",
        action: "check-email",
        label: data.checkUserByEmail ? "existing-user" : "new-user",
      });
      navigate(data.checkUserByEmail ? "/sign-in" : "/sign-up");
    },
  });

  function handleCheck() {
    if (!email || !email.trim() || !isEmail(email.trim())) {
      ReactGA.event({
        category: "request-access",
        action: "check-email",
        label: "invalid",
      });
      return setError(
        "Ingresa una dirección de correo electrónico de trabajo válida.",
      );
    }

    setError(null);
    setStoredEmail(email.trim());
    check({ variables: { email: email.trim() } });
  }

  return (
    <Fade in style={{ transitionDelay: "200ms" }}>
      <Container
        maxWidth="xl"
        sx={{
          minHeight: "100vh",
          py: 4,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack spacing={isMobile ? 6 : 12}>
          <Box textAlign="center">
            <Box component="img" src="/logo.webp" alt="Ventau" maxWidth={300} />
          </Box>
          <Grid
            container
            spacing={2}
            sx={isMobile ? { flexDirection: "column-reverse" } : {}}
          >
            <Grid
              item
              xs={12}
              md={6}
              lg={5}
              xl={4}
              sx={isMobile ? { pr: 4, mt: 4, mb: 2 } : {}}
            >
              <Stack spacing={2}>
                {!isMobile && (
                  <Box pb={4}>
                    <Typography variant="h5">
                      ¡Bienvenido a la nueva era Ventau!
                    </Typography>
                    <Typography>
                      Prepárate para potenciar tu Concesionario con Ventau.
                    </Typography>
                  </Box>
                )}
                <TextField
                  variant="outlined"
                  label="Ingresa tu correo electrónico de trabajo"
                  fullWidth
                  autoFocus
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  disabled={loading}
                  error={Boolean(error)}
                  helperText={error}
                  onKeyDown={(e) => (e.key === "Enter" ? handleCheck() : null)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
                <Box>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    disabled={loading}
                    onClick={handleCheck}
                    {...(!loading ? { className: "gradient" } : {})}
                  >
                    Acceder
                  </Button>
                  {loading && (
                    <LinearProgress
                      color="secondary"
                      sx={{ position: "relative", top: "-4px", opacity: 0.9 }}
                    />
                  )}
                </Box>
                <Typography
                  variant="caption"
                  sx={{ pt: 3, textAlign: "justify", lineHeight: 1.4 }}
                  color="textSecondary"
                >
                  <span>
                    Al ingresar tu correo electrónico confirmas que aceptas que
                    Ventau se comunique contigo acerca de sus productos y
                    servicios. Puedes darte de baja en cualquier momento
                    haciendo clic en "darse de baja" en nuestros mensajes de
                    correo electrónico. Obtén más información sobre cómo usamos
                    tus datos en nuestra&nbsp;
                  </span>
                  <Link href="https://ventau.com/privacidad/">
                    política de privacidad
                  </Link>
                  .
                </Typography>
              </Stack>
            </Grid>
            <Hidden lgDown>
              <Grid item lg={1} xl={2} />
            </Hidden>
            <Grid item xs={12} md={6} sx={isMobile ? { pr: 4 } : {}}>
              <Typography
                variant="h5"
                sx={
                  isMobile ? { mb: 2, textAlign: "center" } : { pl: 11, mb: 2 }
                }
              >
                Inicia en&nbsp;
                <span className="gradient gradient--text">3 simples pasos</span>
              </Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      className="gradient"
                      sx={{ width: 56, height: 56, mr: 2 }}
                    >
                      <PersonAdd />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<b>Crea tu cuenta</b>}
                    secondary="¡Crea tu cuenta gratis! Sin riesgo, rápido y seguro."
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      className="gradient"
                      sx={{ width: 56, height: 56, mr: 2 }}
                    >
                      <VerifiedUser />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<b>Verifica tu negocio</b>}
                    secondary="En una llamada breve, nuestro equipo verificará tu negocio y te ayudará en cualquier duda que puedas tener. Ventau siempre está para apoyarte."
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      className="gradient"
                      sx={{ width: 56, height: 56, mr: 2 }}
                    >
                      <RequestQuote />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<b>¡Empieza a recibir leads de calidad!</b>}
                    secondary="Recibe de manera instantánea contactos híper calificados, listos para acudir a tu Concesionario y vender su auto."
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Stack>
        <WhatsAppButton />
      </Container>
    </Fade>
  );
}

export default RequestAccess;
