import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";
import { LightbulbOutlined } from "@mui/icons-material";
import BusinessIntelligenceDialog from "./BusinessIntelligenceDialog";

function GettingStartedDiscountOwnBrands({ initialState, nextStep, prevStep }) {
  const [discountOwnBrands, setDiscountOwnBrands] = useState(
    initialState.discountOwnBrands || "",
  );

  return (
    <GettingStartedLayout
      image="/market-intelligence.webp"
      noImageTransformations
      flexStart
      noBackgroundLogo
      fullWidthImage
      nextStep={() => nextStep({ discountOwnBrands })}
      prevStep={() => prevStep({ discountOwnBrands })}
      disabled={!discountOwnBrands || !discountOwnBrands.trim()}
    >
      <Box>
        <Typography variant="h5" sx={{ mb: 2 }}>
          A partir del valor promedio de mercado, ¿qué porcentaje de descuento
          haces para comprar autos de tu propias marcas?
          <BusinessIntelligenceDialog trigger="button" />
        </Typography>
        <Typography>
          Al determinar este porcentaje, considera que generalmente puedes
          vender los autos de las marcas de tus Concesionarios por encima de la
          media de mercado. En el siguiente paso podrás definir un porcentaje de
          descuento para otras marcas.
        </Typography>
      </Box>
      <Box>
        <TextField
          variant="outlined"
          autoFocus
          value={discountOwnBrands}
          type="number"
          onChange={(e) => setDiscountOwnBrands(e.target.value)}
          onKeyDown={(e) =>
            e.key === "Enter" && discountOwnBrands && discountOwnBrands.trim()
              ? nextStep({ discountOwnBrands })
              : null
          }
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            componentsProps: {
              input: {
                min: 0,
                max: 100,
              },
            },
          }}
          placeholder="Ej. 13"
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <LightbulbOutlined color="action" sx={{ mr: 1 }} />
        <Typography variant="body2" color="textSecondary">
          Lo más común es trabajar con un descuento de entre el 12% y 15%,&nbsp;
          <BusinessIntelligenceDialog trigger="conoce por qué" />.
        </Typography>
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedDiscountOwnBrands;
