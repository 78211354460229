import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import PictureButton from "./PictureButton";
import CustomPicture from "./CustomPicture";

function BusinessUnitLogo({ logo, ownBrands, onChange }) {
  const [logos, setLogos] = useState([]);

  useEffect(() => {
    const urls = ownBrands
      .map((brand) => {
        const logoAttribute = brand.attributes.find(
          (attribute) => attribute.name === "logo",
        );
        if (!logoAttribute) return null;

        return logoAttribute.value;
      })
      .filter(Boolean);

    setLogos(urls);
    if (!logo && urls.length) onChange(urls[0]);
  }, [ownBrands, logo, onChange]);

  return (
    <Box>
      <Typography sx={{ mb: 3 }}>
        {`Ahora sube el logotipo de tu Concesionario. ${
          logos.length > 0
            ? "También puedes seleccionar una de las opciones que encontramos."
            : ""
        }`}
      </Typography>
      <Box display="flex" flexWrap="wrap">
        {(logos || []).map((url) => (
          <PictureButton
            key={url}
            src={url}
            selected={url === logo}
            onClick={() => onChange(url)}
            variant="logo"
          />
        ))}

        <CustomPicture
          selectedUrl={logo}
          onChange={(url) => onChange(url)}
          variant="logo"
          defaults={logos || []}
        />
      </Box>
    </Box>
  );
}

export default BusinessUnitLogo;
